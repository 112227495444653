import * as actionTypes from 'redux/actionTypes'

const initialState = {
  info: {}
};

export default function deviceReducer(state = initialState, action) {
  
  switch (action.type) {
    
    case actionTypes.SET_DEVICE_INFO: {
      const { info } = action.payload
      return { ...state, info: info }

    }

    case actionTypes.SET_DEVICE_PLATFORM: {
      const { platform } = action.payload
      return { ...state, 
        info: { ...state.info, platform: platform }
      }
      
    }

    default:
      return state
  
  }

}