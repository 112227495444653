import React from 'react'

export const appContext = process.env.REACT_APP_CONTEXT
export const AppContext = React.createContext()

export const AppContextProvider = ({ children }) => {

  return (
    <AppContext.Provider value={appContext}>
      {children}
    </AppContext.Provider>
  )
}

