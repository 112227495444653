import * as React from "react";

const SvgSecondScreenInactive = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 39 33"
    style={{
      enableBackground: "new 0 0 39 33",
    }}
    xmlSpace="preserve"
    width="1em"
    height="1em"
    role="img"
    {...props}
  >
    <path d="M39 23H0V0h39v23zM2 21h35V2H2v19zm11.5 12h12l-6-7-6 7zm7-28.1h-2v5.6h-5.6v2h5.6v5.6h2v-5.6h5.6v-2h-5.6V4.9z" />
  </svg>
);

export default SvgSecondScreenInactive;
