// Core
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

// Data
import { Provider } from 'react-redux'
import store from 'redux/store'
import { initServiceWorker, updateServiceWorker } from 'redux/actions'

// Vendor
import TagManager from 'react-gtm-module'

// Error Boundary (Sentry)
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

// PWA
import * as serviceWorkerRegistration from 'serviceWorkerRegistration'

// Custom Components
import Button from 'components/button'

// Analytics
//import reportWebVitals from 'reportWebVitals';

// Custom
import App from 'App'
import Loader from 'utils/loader'

// TODO: check order of these is correct for overriding each other
import 'default-theme.scss'

import 'index.scss'

import 'custom.scss'

Sentry.init({
  dsn: 'https://0c81163a719b4778b4015df8798d9af9@sentry.yellostudio.co.uk/23',
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
})

function gtag() {
  const dataLayer = window.dataLayer || []
  console.log(dataLayer, 'INIT')
  dataLayer.push(arguments)
}

const gtmCode = process.env.REACT_APP_GTM_CODE

if (gtmCode) {
  const tagManagerArgs = {
    gtmId: gtmCode,
  }

  TagManager.initialize(tagManagerArgs)

  gtag('consent', 'default', {
    ad_storage: 'denied',
    analytics_storage: 'denied',
    functionality_storage: 'denied',
    personalization_storage: 'denied',
    security_storage: 'denied',
  })
}

const ErrorFallback = () => {
  const style = {
    width: '340px',
    margin: 'auto',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  }

  return (
    <div style={style}>
      <h2>Sorry, there seems to be a problem!</h2>
      <p>Our team has been informed of the issue, please return to the homescreen to try again.</p>
      <br />
      <Button label="Return to Homescreen" onClick={() => (window.location.href = window.location.origin)} />
    </div>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
      <Provider store={store}>
        <Loader />
        <Router>
          <App />
        </Router>
      </Provider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

const SWConfig = {
  onSuccess: () => store.dispatch(initServiceWorker()),
  onUpdate: (registration) => store.dispatch(updateServiceWorker(registration)),
}

serviceWorkerRegistration.register(SWConfig)
//serviceWorkerRegistration.unregister();
