// Data
import { useDispatch, useSelector } from "react-redux"
import { toggleConfirmDialog } from "redux/actions"

// Custom
import DropPanel from "components/drop-panel"

function ConfirmClose() {

  const dispatch = useDispatch()

  const { confirmDialogOpen, lastCachedTime } = useSelector(({state}) => ({
    confirmDialogOpen: state.confirmDialogOpen,
    lastCachedTime: state.lastCachedTime,
  }))

  return (

    <DropPanel id="confirm" trigger={confirmDialogOpen}>

      <p>Are you sure you wish to close this application?</p>
      <div>
        <button className="button button--regular" onClick={() => dispatch( toggleConfirmDialog(false) )} >Cancel</button>
        <button className="button button--regular" onClick={() => window.close() } >Confirm</button>
        <br /><br />
        <p>Assets last cached { lastCachedTime }</p>
      </div>

    </DropPanel>

  )
}

export default ConfirmClose