// Data
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

// Custom
import Scene from 'components/scene'
import Button from 'components/button'
import ImageGallery from 'components/image-gallery'
import VideoGallery from 'components/video-gallery'
import EmbedGallery from 'components/embed-gallery'
import TourIframe from 'components/tour-iframe'

function NoElement() {
  const history = useHistory()

  return (
    <div className="no-enlarged-asset">
      <h2>No element to view, please return to the home screen</h2>
      <Button onClick={() => history.push('/')} label="Back to home screen"></Button>
    </div>
  )
}

function Enlarge() {
  const { enlargedType, enlargedAssets } = useSelector(({ state }) => ({
    enlargedType: state.enlargedType,
    enlargedAssets: state.enlargedAssets,
  }))

  return (
    <Scene extraClass="large-view slide-gallery">
      {{
        gallery: <ImageGallery images={enlargedAssets} />,
        single: <ImageGallery images={enlargedAssets} />,
        videoUrl: <VideoGallery videoId="enlargedVideo" video={enlargedAssets[0]} />,
        videoEmbed: <EmbedGallery asset={enlargedAssets[0]} />,
        tour: <EmbedGallery asset={enlargedAssets[0]} />,
        iframe: <TourIframe iframeId="enlargedIframe" iframeSrc={enlargedAssets[0]} height={'100vh'} />,
      }[enlargedType] || <NoElement />}
    </Scene>
  )
}

export default Enlarge
