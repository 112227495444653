import * as React from "react";

const SvgLandingPageArrowPrompt = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 24"
    width="1em"
    height="1em"
    role="img"
    {...props}
  >
    <path d="M0 0h48L24 24z" />
  </svg>
);

export default SvgLandingPageArrowPrompt;
