import { setCookie } from './helpers'

function gtag() {
  const dataLayer = window.dataLayer || []
  dataLayer.push(arguments)
  return true
}

function useGtmConsent() {
  const updateConsent = async (type) => {
    console.log(type)

    let values = {}

    switch (type) {
      case 'ALL':
        values = {
          ad_storage: 'granted',
          analytics_storage: 'granted',
          functionality_storage: 'granted',
          personalization_storage: 'granted',
          security_storage: 'granted',
        }

        setCookie('gtm-consent', JSON.stringify(values), 365)
        break

      case 'ESSENTIAL':
        values = {
          ad_storage: 'denied',
          analytics_storage: 'denied',
          functionality_storage: 'granted',
          personalization_storage: 'denied',
          security_storage: 'granted',
        }

        setCookie('gtm-consent', JSON.stringify(values), 365)
        break

      case 'REJECT':
        values = {
          ad_storage: 'denied',
          analytics_storage: 'denied',
          functionality_storage: 'denied',
          personalization_storage: 'denied',
          security_storage: 'denied',
        }

        setCookie('gtm-consent', JSON.stringify(values), 365)
        break
    }

    gtag('consent', 'update', values)
  }

  const setCookieConsent = (values) => {
    gtag('consent', 'update', values)
  }

  return { gtag, setCookieConsent, updateConsent }
}

export default useGtmConsent
