import { useSelector } from 'react-redux'

import useGtmConsent from 'utils/gtm-consent'

import { getCookie } from 'utils/helpers'

import { useContext, useEffect, useState } from 'react'
import { AppContext } from 'utils/context'

// SCSS
import 'components/cookie-consent-bar.scss'

function CookieConsentBar() {
  const consent = useGtmConsent()
  const appContext = useContext(AppContext)

  const [showCookieBar, setShowCookieBar] = useState(false)
  const [showManageButtons, setShowManageButtons] = useState(false)

  const project = useSelector(({ data }) => data.project)

  const acceptCookies = () => {
    consent.updateConsent('ALL')
    setShowCookieBar(false)
  }

  const essentialCookies = async () => {
    await consent.updateConsent('ESSENTIAL')
    setShowCookieBar(false)
  }

  const rejectCookies = async () => {
    await consent.updateConsent('REJECT')
    setShowCookieBar(false)
  }

  const manageCookies = () => {
    setShowManageButtons(true)
  }

  useEffect(() => {
    const checkConsent = () => {
      const existingCookie = getCookie('gtm-consent')

      if (existingCookie) {
        consent.setCookieConsent(JSON.parse(existingCookie))
      } else {
        if (appContext == 'public') {
          setShowCookieBar(true)
        } else {
          consent.updateConsent('ALL')
        }
      }
    }

    checkConsent()
  }, [])

  return showCookieBar ? (
    <div className="cookie-consent-bar">
      <h2>{project.cookieNoticeTitle || 'This website uses cookies'}</h2>
      <div dangerouslySetInnerHTML={{ __html: project.cookieNoticeText }}></div>

      <div className="cookie-buttons">
        <div className="terms-links">
          {project.termsOfUseUrl && <button onClick={() => window.open(project.termsOfUseUrl, '_blank')}>Terms of use</button>}
          {project.privacyCookiePolicyUrl && <button onClick={() => window.open(project.privacyCookiePolicyUrl, '_blank')}>Cookie policy</button>}
        </div>
        <div className="button-container">
          {showManageButtons ? (
            <>
              <button className="decline" onClick={() => rejectCookies()}>
                Reject All
              </button>
              <button className="essential" onClick={() => essentialCookies()}>
                Essential Cookies Only
              </button>
            </>
          ) : (
            <button className="manage" onClick={() => manageCookies()}>
              Manage Cookies
            </button>
          )}
          <button className="accept" onClick={() => acceptCookies()}>
            Accept Cookies
          </button>
        </div>
      </div>
    </div>
  ) : null
}

export default CookieConsentBar
