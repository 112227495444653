// Data
import { useSelector } from 'react-redux'

// Animation
import { motion, AnimatePresence } from 'framer-motion'
import { variants } from 'utils/animation'

// SCSS
import 'components/drop-panel.scss'

function DropPanel({ children, trigger, ...props}){

  const deviceInfo = useSelector(state => state.device.info)

  return (

    <AnimatePresence>

      { trigger &&

        <motion.div 
          className="drop-panel" 
          animate={trigger ? "open" : "closed"}
          exit={"closed"}
          variants={deviceInfo && deviceInfo.platform === "electron" ? variants.drop_panel_bottom : variants.drop_panel_top} 
          { ...props }
        >
          {children}
        </motion.div>
      
      }

    </AnimatePresence>

  )
  

}


export default DropPanel