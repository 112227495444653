import * as React from "react";

const SvgRotatePrompt = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 108 88"
    width="1em"
    height="1em"
    role="img"
    {...props}
  >
    <path
      d="M107 51.1c0-2.8-2.3-5.1-5.1-5.1H26.1c-2.8 0-5.1 2.3-5.1 5.1v30.7c0 2.8 2.3 5.1 5.1 5.1h75.7c2.8 0 5.1-2.3 5.1-5.1V51.1z"
      opacity={0.4}
    />
    <path d="m80.6 31.3-5.8 6.2-5.8-6.3h4.8v-.9c-.3-9.3-7.7-16.9-16.9-17.6v4.8l-6.3-5.8L56.8 6v4.9c10.3.7 18.6 9.2 18.9 19.5v1l4.9-.1zM108 51.1v30.7c0 3.4-2.8 6.1-6.1 6.1H6.1c-3.3.1-6.1-2.7-6.1-6V6.1C0 2.8 2.8 0 6.1 0h30.7C40.2 0 43 2.8 43 6.1V45h58.9c3.3 0 6.1 2.8 6.1 6.1zM6.1 86h15.5c-1-1.1-1.6-2.5-1.6-4.1V51.1c0-3.4 2.8-6.1 6.1-6.1H41V6.1C41 3.9 39.1 2 36.9 2H6.1C3.9 2 2 3.9 2 6.1v75.7C2 84.1 3.9 86 6.1 86zm30.8 0c2.3 0 4.1-1.9 4.1-4.1V47H26.1c-2.3 0-4.1 1.9-4.1 4.1v30.7c0 2.3 1.9 4.1 4.1 4.1h10.8zM106 51.1c0-2.3-1.9-4.1-4.1-4.1H43v34.9c0 1.6-.6 3-1.6 4.1h60.5c2.3 0 4.1-1.9 4.1-4.1V51.1z" />
  </svg>
);

export default SvgRotatePrompt;
