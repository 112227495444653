import * as React from "react";

const SvgPlayButton = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 30"
    style={{
      enableBackground: "new 0 0 25 30",
    }}
    xmlSpace="preserve"
    width="1em"
    height="1em"
    role="img"
    {...props}
  >
    <path d="M0 0v30l25-15z" />
  </svg>
);

export default SvgPlayButton;
