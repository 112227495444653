import * as React from "react";

const SvgShare = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 33 33"
    width="1em"
    height="1em"
    role="img"
    {...props}
  >
    <path d="M27.5 22c-1.7 0-3.3.8-4.3 2.1l-12.3-6.5c.1-.4.1-.7.1-1.1s0-.8-.1-1.1l12.3-6.5c1 1.3 2.6 2.1 4.3 2.1 3 0 5.5-2.5 5.5-5.5S30.5 0 27.5 0 22 2.5 22 5.5c0 .6.1 1.1.3 1.7l-12.1 6.4C9.1 12 7.4 11 5.5 11c-3 0-5.5 2.5-5.5 5.5S2.5 22 5.5 22c1.9 0 3.6-1 4.6-2.5l12.1 6.4c-.2.5-.3 1.1-.3 1.7 0 3 2.5 5.5 5.5 5.5s5.5-2.5 5.5-5.5c.1-3.1-2.4-5.6-5.4-5.6z" />
  </svg>
);

export default SvgShare;
