import * as React from "react";

const SvgInstagram = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
    width="1em"
    height="1em"
    role="img"
    {...props}
  >
    <path d="M30 0c-8.1 0-9.2 0-12.4.2-3.2.1-5.4.7-7.3 1.4C8.4 2.3 6.7 3.4 5 5s-2.7 3.3-3.5 5.3C.8 12.2.3 14.4.1 17.6 0 20.8 0 21.9 0 30c0 8.1 0 9.2.2 12.4.1 3.2.7 5.4 1.4 7.3.8 2 1.8 3.6 3.5 5.3 1.7 1.7 3.3 2.7 5.3 3.5 1.9.7 4.1 1.2 7.3 1.4 3.1.1 4.2.1 12.3.1s9.2 0 12.4-.2c3.2-.1 5.4-.7 7.3-1.4 2-.8 3.6-1.8 5.3-3.5 1.7-1.7 2.7-3.3 3.5-5.3.7-1.9 1.2-4.1 1.4-7.3.1-3.1.1-4.2.1-12.3 0-8.1 0-9.2-.2-12.4-.1-3.2-.7-5.4-1.4-7.3-.8-2-1.8-3.6-3.5-5.3s-3.3-2.7-5.3-3.5C47.7.8 45.5.3 42.3.1 39.2 0 38.1 0 30 0m0 5.4c8 0 9 0 12.1.2 2.9.1 4.5.6 5.6 1 1.4.5 2.4 1.2 3.5 2.2 1 1 1.7 2 2.2 3.4.4 1.1.9 2.6 1 5.6.2 3.2.2 4.2.2 12.2 0 8 0 9-.2 12.1-.1 2.9-.6 4.5-1 5.6-.5 1.4-1.2 2.4-2.2 3.4-1 1-2 1.7-3.5 2.2-1.1.4-2.6.9-5.6 1-3.1.3-4.1.3-12.1.3s-9 0-12.1-.2c-2.9-.1-4.5-.6-5.6-1-1.4-.5-2.4-1.2-3.4-2.2-1-1-1.7-2-2.2-3.4-.4-1.1-.9-2.6-1-5.6C5.4 39 5.4 38 5.4 30c0-8 0-9 .2-12.1.1-2.9.6-4.5 1-5.6.5-1.4 1.2-2.4 2.2-3.4 1-1.1 2-1.7 3.4-2.2 1.1-.4 2.6-.9 5.6-1C21 5.4 22 5.4 30 5.4" />
    <path d="M30 40c-5.5 0-10-4.5-10-10s4.5-10 10-10 10 4.5 10 10-4.5 10-10 10m0-25.4c-8.5 0-15.4 6.9-15.4 15.4S21.5 45.4 30 45.4 45.4 38.5 45.4 30 38.5 14.6 30 14.6m19.6-.6c0 2-1.6 3.6-3.6 3.6S42.4 16 42.4 14s1.6-3.6 3.6-3.6 3.6 1.6 3.6 3.6" />
  </svg>
);

export default SvgInstagram;
