import 'components/embed-gallery.scss'

function EmbedGallery({ asset }){

  // TODO: Extra play button to swap out placeholder for actual content.

  return (
    <div className="embed-gallery" dangerouslySetInnerHTML={{ __html: asset }}></div>
  )

}

export default EmbedGallery