export const JsonDump = (props) => <pre>{JSON.stringify(props.data, null, 2)}</pre>

export const Debug = (props) => <div className="debug">{props.children}</div>

export const Currency = ({ value }) => {
  if (!value) return '£0'
  return '£' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const NumberFormat = ({ value }) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const Capitalize = ([first, ...rest], lowerRest = false) => first.toUpperCase() + (lowerRest ? rest.join('').toLowerCase() : rest.join(''))

export const metresToMiles = (metres) => {
  const factor = 0.621371
  return (metres / 1000) * factor
}

export const milesToMetres = (miles) => {
  const factor = 1609.344
  return miles * factor
}

export function debounce(fn, ms) {
  let timer
  return () => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  }
}

export const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)

export const minsToHoursMins = (mins) => {
  console.log('MINS TO ', mins)

  var hours = Math.floor(mins / 60)
  var minutes = mins % 60

  //return hours + ': ' + minutes

  let timeStr = hours > 0 ? `${hours} hour` : ''
  timeStr += hours > 1 ? 's' : ''
  timeStr += minutes > 0 ? ` ${minutes} mins` : ''

  return timeStr
}

export const timeToSeconds = (time) => {
  let array = time.split(':')
  let seconds = parseInt(array[0], 10) * 60 * 60 + parseInt(array[1], 10) * 60 + parseInt(array[2], 10)

  return seconds / 60
}

export const timeToHourMins = (time) => {
  let array = time.split(':')
  let seconds = parseInt(array[0], 10) * 60 * 60 + parseInt(array[1], 10) * 60 + parseInt(array[2], 10)

  var h = Math.floor(seconds / 3600)
  var m = Math.floor((seconds % 3600) / 60)
  var s = Math.floor((seconds % 3600) % 60)

  var hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : ''
  var mDisplay = m > 0 ? m + (m == 1 ? ' min ' : ' mins ') : ''

  return hDisplay + mDisplay
}

export const loadImage = (url, num) => {
  return new Promise((resolve) => {
    fetch(url)
      // Extract as a blob
      .then((resp) => resp.blob())
      .then((blob) => {
        //console.log(blob)
        // Image element to load the image into. Could be passed as a variable if you already have an element to load into.
        const img = document.createElement('img')
        // Use blob as object url
        img.src = URL.createObjectURL(blob)

        // wait for image to be loaded before resolving the promise
        img.onload = () => {
          resolve(img)
        }
      })
  })
}

// Cookies:

export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  let expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

export const getCookie = (cname) => {
  let name = cname + '='
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}
