import * as React from "react";

const SvgYoutube = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 86 60"
    width="1em"
    height="1em"
    role="img"
    {...props}
  >
    <path d="M34.3 42.7V17.3L56.5 30 34.3 42.7zM83.8 9.4c-1-3.7-3.9-6.6-7.5-7.6C69.6 0 43 0 43 0S16.4 0 9.7 1.8C6 2.8 3.1 5.7 2.2 9.4.4 16.1.4 30 .4 30s0 13.9 1.8 20.6c1 3.7 3.9 6.6 7.5 7.6C16.4 60 43 60 43 60s26.6 0 33.3-1.8c3.7-1 6.6-3.9 7.5-7.6 1.8-6.7 1.8-20.6 1.8-20.6s0-13.9-1.8-20.6" />
  </svg>
);

export default SvgYoutube;
