// Core
import { forwardRef, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

// Data
import { useDispatch } from 'react-redux'
import { setEnlargedAssets } from 'redux/actions'

// Hooks
import { useController } from 'utils/controller'

// Custom
import Image from 'components/image'
import IconButton from 'components/icon-button'

// Icons
import ThreeSixtyButton from 'icons/360Button'
import ExpandButton from 'icons/ExpandButton'

// CSS
import 'components/tour-embed.scss'

const TourIframe = ({ iframeId, iframeSrc, thumbnail, height, showButton = false, extraClass = false, withZoom = false }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const controller = useController()

  const [trigger, setTrigger] = useState(false)

  useEffect(() => {
    if (trigger) {
      controller.send({ action: `iframeTourTrigger${iframeId}`, refKey: iframeId })
    }
  }, [trigger])

  useEffect(() => {
    controller.on(`iframeTourTrigger${iframeId}Response`, ({ refKey }) => {
      setTrigger(true)
    })

    controller.on('setEnlargedAssetsResponse', ({ type, assets }) => {
      dispatch(
        setEnlargedAssets({
          enlargedType: type,
          enlargedAssets: assets,
        })
      )
    })
  })

  const messageEvent = (event) => {
    const { slug } = event.data

    switch (event.data.event) {
      case 'view_updated':
        const { h, v, fov } = event.data.position
        // console.log("UPDATE REMOTE VIEW TO", event.data.slug, h, v)
        controller.send({ action: 'iframeTour', refKey: iframeId, event: event.data.event, slug, position: { h, v, fov } })
        break

      case 'scene_updated':
        const { scene } = event.data
        // console.log("UPDATE REMOTE SCENE WITH", event.data.slug, event.data.scene)
        controller.send({ action: 'iframeTour', refKey: iframeId, event: event.data.event, slug, scene })
        break
    }
  }

  useEffect(() => {
    window.top.addEventListener('message', messageEvent)

    return () => window.top.removeEventListener('message', messageEvent)
  }, [])

  const enlargeAssets = () => {
    controller.send({ action: 'setEnlargedAssets', type: 'iframe', assets: [iframeSrc] })

    dispatch(
      setEnlargedAssets({
        enlargedType: 'iframe',
        enlargedAssets: [iframeSrc],
      })
    )

    setTimeout(() => {
      history.push('/enlarge')
    })
  }

  return (
    <div className={['tour-embed', extraClass && extraClass].join(' ')}>
      {showButton ? (
        trigger ? (
          <iframe id={iframeId} src={iframeSrc} style={{ height: height + 'px' }}></iframe>
        ) : (
          <div className="video-embed-placeholder" style={{ height: height + 'px' }}>
            <Image src={thumbnail} />

            <IconButton onClick={() => setTrigger(true)}>
              <ThreeSixtyButton />
            </IconButton>
          </div>
        )
      ) : (
        <iframe id={iframeId} src={iframeSrc} style={{ height: height + 'px' }}></iframe>
      )}

      {withZoom && (
        <IconButton extraClass="tour-zoom-button" onClick={() => enlargeAssets()}>
          <ExpandButton />
        </IconButton>
      )}
    </div>
  )
}

export default TourIframe
