// Core
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

// Hooks
import { useController } from 'utils/controller'

// Socket IO
import { setSocket, setConnectedDevices, setSelectedDevice, toggleAllSplashScreens } from 'redux/actions'
import { useHistory } from 'react-router'

function Remote() {
  const dispatch = useDispatch()
  const history = useHistory()
  const controller = useController()

  useEffect(() => {
    controller.on('connectedList', (response) => {
      dispatch(setConnectedDevices(response))
    })

    controller.on('goToPageResponse', (response) => {
      if (response.showSplash) {
        history.push(response.pathname)
        setTimeout(() => dispatch(toggleAllSplashScreens(true)), 700)
      } else if (response.hideSplash) {
        dispatch(toggleAllSplashScreens(false))
        setTimeout(() => history.push(response.pathname), 700)
      } else {
        history.push(response.pathname)
      }
    })

    controller.on('disconnect', () => {
      console.log('DISCONNECTED')

      dispatch(setSocket(null))
      dispatch(setSelectedDevice(null))
      dispatch(setConnectedDevices({}))

      setTimeout(() => dispatch(toggleAllSplashScreens(false)), controller.remoteData.screensaverTimeout)
    })

    if (!controller.connected) {
      controller.connect()
    }
  }, [controller.connected])

  // Exit when no remote server URL exists
  if (!process.env.REACT_APP_REMOTE_SERVER) {
    return false
  }

  return null
}

export default Remote
