// Socket
import socketIOClient from 'socket.io-client'

// Data
import { useDispatch, useSelector } from 'react-redux'
import { setSocket, setLightingSocket } from 'redux/actions'

export function useController() {
  const dispatch = useDispatch()

  const { socket, lightingSocket, remoteData, selectedDevice } = useSelector(({ remote }) => remote)

  const connected = socket && socket.connected

  // const hasModel = process.env.REACT_APP_MODEL || false
  const hasServer = process.env.REACT_APP_REMOTE_SERVER || false

  const connect = () => {
    if (hasServer) {
      const masterClient = socketIOClient(`${process.env.REACT_APP_REMOTE_SERVER}:4000`)

      masterClient.once('connect', () => {
        dispatch(setSocket(masterClient))
        masterClient.emit('identification', remoteData.channel)
      })

      if (remoteData.sendLightingCommands) {
        const modelLightingSocketPath = remoteData.modelLightingServer ? remoteData.modelLightingServer : process.env.REACT_APP_REMOTE_SERVER

        const lightingClient = socketIOClient(`${modelLightingSocketPath}:5000`)

        lightingClient.once('connect', () => {
          dispatch(setLightingSocket(lightingClient))
          lightingClient.emit('identification', remoteData.channel)
        })
      }
    }
  }

  const send = (params) => {
    if (connected && selectedDevice) {
      console.log('SEND', params, selectedDevice)
      params['initiatingChannel'] = remoteData.channel
      params['selectedChannel'] = selectedDevice.channel
      socket.emit(params.customAction || 'doAction', params)
    } else {
      console.log('%cCANCELLED EMIT', 'color: orange', params)
    }
  }

  const switchDevice = (params) => {
    socket.emit('doAction', params)
  }

  const sendLighting = (params) => {
    console.count('LIGHTING COUNT', params)

    // TODO: These should be able to be removed with updates to lighting server
    params['master'] = true
    params['deviceId'] = 1
    params['applicationIdentifier'] = remoteData.channel
    params['channel'] = 8080

    if (connected && remoteData.sendLightingCommands) {
      console.log('SEND THE LIGHTING', lightingSocket)

      if (lightingSocket) {
        console.log('SEND LIGHTING TO SLAVE LIGHTING SERVER', params, selectedDevice)
        lightingSocket.emit(params.customAction || 'lightPlots', params)
      } else {
        console.log('SEND LIGHTING', params, selectedDevice)
        socket.emit(params.customAction || 'lightPlots', params)
      }
    } else {
      console.log('%cCANCELLED LIGHTING EMIT', 'color: orange', params)
    }
  }

  const on = (action, callback) => {
    if (connected) {
      //console.log('BIND: ', action)
      socket.off(action).on(action, (response) => callback(response))
    }
  }

  const off = (action) => {
    if (connected) {
      //console.log('UNBIND: ', action)
      socket.off(action)
    }
  }

  return { socket, connected, remoteData, connect, send, switchDevice, sendLighting, on, off }
}
