// Routing
import { useHistory } from 'react-router-dom'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { toggleAllSplashScreens } from 'redux/actions'

// Idle
import { useIdleTimer } from 'react-idle-timer'

function ScreenSaver() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { screensaverTimeout, disableScreenSaver } = useSelector(({ data, state }) => ({
    screensaverTimeout: data.project.screensaverTimeoutMs,
    disableScreenSaver: state.disableScreenSaver,
  }))

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: screensaverTimeout,
    onIdle: () => {
      if (screensaverTimeout && screensaverTimeout > 0 && !disableScreenSaver) {
        history.push('/')
        dispatch(toggleAllSplashScreens(true))
      }
    },
    debounce: 500,
  })

  return null
}

export default ScreenSaver
