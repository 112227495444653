// Vendor
import nl2br from 'react-nl2br'

// Data
import { useSelector } from "react-redux"

// Custom
import Image from "components/image";
import Scene from 'components/scene';

// Icons
import FacebookIcon from "icons/Facebook"
import InstagramIcon from "icons/Instagram"
import LinkedInIcon from "icons/Linkedin"
import PinterestIcon from "icons/Pinterest"
import TwitterIcon from "icons/Twitter"
import VimeoIcon from "icons/Vimeo"
import YoutubeIcon from "icons/Youtube"

// CSS
import './contact.scss'

const iconMap = {
  "facebook": <FacebookIcon />,
  "instagram": <InstagramIcon />,
  "linkedIn": <LinkedInIcon />,
  "pinterest": <PinterestIcon />,
  "twitter": <TwitterIcon />,
  "vimeo": <VimeoIcon />,
  "youtube": <YoutubeIcon />,
}

function Contact() {

  const { project, contact } = useSelector(({ data }) => ({
    project: data.project, 
    contact: data.modules.contact 
  }))
  
  return (

    <Scene extraClass="contact-scene">

      {/* TODO: Can contact class be moved to parent extraClass? */}
      <div className="contact"> 

        <p className="social-links">

          { contact.socialMedia.map(platform => (
            <a key={platform.provider} href={platform.url} target="_blank" rel="noreferrer">
              { iconMap[platform.provider] }
            </a>
          ))}

        </p>
        
        <br /><br />
        
        <h1>{ contact.title }</h1>

        <br /><br />

        { contact.companyName && <p className="company">{ contact.companyName }</p> }
        { contact.address && <p><a href={`https://maps.google.com/?q=${contact.address.replace(/ /g, '+').replace(/\n/g, '+')}`} target="_blank" rel="noreferrer">{ nl2br(contact.address) }</a></p> }

        <br /><br />

        { contact.email && <p><a href={`mailto:${contact.email}`}>{ contact.email }</a></p> }
        { contact.telephone && <p><a href={`tel:${contact.telephone.replace(" ", "")}`}>{ contact.telephone }</a></p> }


        {contact.contactBlocks.length > 0 &&
          <>
            <br /><br />
            <p className='company'>{ contact.contactTitle }</p><br />
            { contact.contactBlocks.map((contactBlock, index) => (
              <div key={index.toString()} style={{ marginBottom: 20 }}>
                { contactBlock.contactTitle && <h4>{contactBlock.contactTitle}</h4> }
                <p>
                  { contactBlock.address && <><a href={`https://maps.google.com/?q=${contact.address.replace(/ /g, '+').replace(/\n/g, '+')}`} target="_blank" rel="noreferrer">{ nl2br(contactBlock.address) }</a><br /></> }
                  { contactBlock.email && <><a href={`mailto:${contactBlock.email}`}>{ contactBlock.email }</a><br /></> }
                  { contactBlock.telephone && <><a href={`tel:${contactBlock.telephone.replace(" ", "")}`}>{ contactBlock.telephone }</a></> }
                </p>
              </div>
            ))}
          </>
        }

        { project.footerLogo && 
          <Image className="footer-logo" src={project.footerLogo} alt={project.developmentName} />
        }

        { contact.disclaimerUrl &&
          <a href={contact.disclaimerUrl} className="disclaimer" target="_blank" rel="noreferrer">{ contact.disclaimerTitle ? contact.disclaimerTitle : 'Disclaimer'}</a>
        }
        
      </div>

    </Scene>

  )

}

export default Contact
