// Core
import React, { useEffect } from 'react';

// Data
import { useDispatch } from 'react-redux';

// Generic Components
import Button  from 'components/button'

import 'components/alert.scss';

const Alert = ({ text, buttonText, fn, onClick }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!onClick) {
      const timer = setTimeout(() => {
        dispatch( fn() );
      }, 6000);
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <div className="alert">
      {text} 
      {buttonText && 
        <Button  label={buttonText} onClick={() => onClick()} />
      }
    </div>
  );
};

export default Alert;