import * as React from "react";

const SvgFilter = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 39 26"
    width="1em"
    height="1em"
    role="img"
    {...props}
  >
    <path d="M39 6.5H18c-.5 2.6-2.7 4.5-5.4 4.5S7.7 9.1 7.2 6.5H0v-2h7.2C7.7 1.9 9.9 0 12.6 0s4.9 1.9 5.4 4.5h21v2zm0 13h-7.2c-.5-2.6-2.7-4.5-5.4-4.5s-4.9 1.9-5.4 4.5H0v2h21c.5 2.6 2.7 4.5 5.4 4.5s4.9-1.9 5.4-4.5H39v-2z" />
  </svg>
);

export default SvgFilter;
