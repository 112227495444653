// Core
import { useContext, useEffect, useState } from 'react'

// Data
import { useDispatch, useSelector } from 'react-redux'
import { setSaved, setFilterOption, toggleShare } from 'redux/actions'

// Custom
import Image from 'components/image'
import DropPanel from 'components/drop-panel'
import Button from 'components/button'

// Form
import Form from 'components/form'
import { useLocation } from 'react-router-dom'

// Utils
import { AppContext } from 'utils/context'
import useShare from 'utils/share'

function Share() {
  const appContext = useContext(AppContext)
  const location = useLocation()
  const dispatch = useDispatch()
  const share = useShare()

  const { shareOpen, project, compareLeftPlot, compareRightPlot, modelComparePlots, forms, site, saved } = useSelector(({ state, data }) => ({
    shareOpen: state.shareOpen,
    project: data.project,
    compareLeftPlot: state.compareLeftPlot,
    compareRightPlot: state.compareRightPlot,
    modelComparePlots: state.modelComparePlots,
    forms: data.form,
    site: data.site,
    saved: data.modules.property.plots.map((plot) => (plot.favourite ? plot.id : false)).filter((plot) => plot),
  }))

  const [shareCode, setShareCode] = useState('')
  const [savedCode, setSavedCode] = useState('')

  const plotId = location.pathname.split('/').pop()

  let compareIds = []

  useEffect(() => {
    if (compareLeftPlot || compareRightPlot) {
      if (compareLeftPlot) {
        compareIds.push(compareLeftPlot.id)
      }

      if (compareRightPlot) {
        compareIds.push(compareRightPlot.id)
      }
    }

    if (modelComparePlots && !(compareLeftPlot || compareRightPlot)) {
      compareIds = modelComparePlots.map((modelCompare) => modelCompare.plotData.id)
    }
  })

  useEffect(() => {
    if (!shareOpen) {
      setShareCode('')
    }
  }, [shareOpen])

  const generateCode = async () => {
    const res = await share.generateCode(site.id, saved)
    if (!res.success) {
      alert('Unable to generate a new share code.... please try later')
    }
    setShareCode(res.data.code)
  }

  const retrieveCode = async (event) => {
    event.preventDefault()
    const res = await share.retrievePlotList(savedCode)
    if (!res.success) {
      alert('There was a problem retrieving the list of favourites, please try later')
      return false
    }
    window.localStorage.setItem('saved', JSON.stringify(res.data.data))
    dispatch(setSaved(res.data.data))
    dispatch(toggleShare(false))
    setTimeout(() => {
      dispatch(setFilterOption('favourite', true))
      setSavedCode('')
    }, 1000)
  }

  return (
    <DropPanel id="share" trigger={shareOpen}>
      {location.pathname.includes('detail') ? (
        appContext === 'marketing-suite' ? (
          <>
            <h1>{forms.emailAProperty.settings.formTitle || 'Email a Flat'}</h1>
            <Form formHandle="emailAProperty" submitButtonText="Send" sharePlot={[plotId]} />
          </>
        ) : (
          <>
            <h1>{forms.sharePlot.settings.formTitle || 'Email a Flat'}</h1>
            <Form formHandle="sharePlot" submitButtonText="Send" sharePlot={[plotId]} />
          </>
        )
      ) : location.pathname.includes('schedule') && (compareLeftPlot || compareRightPlot) ? (
        <>
          <h1>{forms[appContext === 'marketing-suite' ? 'emailAProperty' : 'sharePlot'].settings.formTitle || 'Email a Flat'}</h1>
          <Form formHandle={appContext === 'marketing-suite' ? 'emailAProperty' : 'sharePlot'} submitButtonText="Send" sharePlot={compareIds} />
        </>
      ) : (
        <>
          <h1>{forms.shareURL.settings.formTitle || 'Share'}</h1>
          <Form formHandle="shareURL" submitButtonText="Send" sharePlot={false} />
        </>
      )}

      {location.pathname.includes('schedule') && appContext == 'public' && (
        <>
          {saved.length > 1 ? (
            <>
              <h2>Share {saved.length} Favourites</h2>

              {shareCode ? (
                <>
                  <h3>Code: {shareCode}</h3>
                  <p>Share this generated code on another device</p>
                </>
              ) : (
                <Button extraClass={'generate-code-button'} label={'Generate Code'} onClick={() => generateCode()} />
              )}
            </>
          ) : (
            <>
              <h2>Share Favourites</h2>
              <h4>Please favourite 1 or more plots in order to share</h4>
            </>
          )}

          <form className="drop-panel-form" onSubmit={retrieveCode}>
            <h2>Load favourites</h2>
            <input type="text" placeholder="Enter a share code..." value={savedCode} onChange={(event) => setSavedCode(event.target.value)} />
            <Button type="submit" label={'Load Favourites'} />
          </form>
        </>
      )}

      {project.footerLogo && <Image className="footer-logo" src={project.footerLogo} alt={project.developmentName} />}
    </DropPanel>
  )
}

export default Share
