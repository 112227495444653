import * as React from "react";

const SvgTwitter = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 74 60"
    width="1em"
    height="1em"
    role="img"
    {...props}
  >
    <path d="M73.9 7.1c-2.7 1.2-5.6 2-8.7 2.4 3.1-1.9 5.5-4.8 6.7-8.4-2.9 1.7-6.2 3-9.6 3.7-2.8-3-6.7-4.8-11.1-4.8-8.4 0-15.1 6.8-15.1 15.1 0 1.2.1 2.3.4 3.5C23.9 18 12.7 11.9 5.2 2.8c-1.3 2.2-2 4.8-2 7.6 0 5.3 2.7 9.9 6.7 12.6-2.5-.1-4.8-.8-6.9-1.9v.2c0 7.3 5.2 13.5 12.2 14.9-1.3.3-2.6.5-4 .5-1 0-1.9-.1-2.8-.3 1.9 6 7.5 10.4 14.2 10.5-5.2 4.1-11.7 6.5-18.8 6.5-1.2 0-2.4-.1-3.6-.2C6.8 57.5 14.8 60 23.3 60c27.9 0 43.1-23.1 43.1-43.1v-2c2.9-2.1 5.5-4.8 7.5-7.8" />
  </svg>
);

export default SvgTwitter;
