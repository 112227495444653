import * as React from "react";

const SvgClose = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    width="1em"
    height="1em"
    role="img"
    {...props}
  >
    <path d="m17.9 5-.9-.9-6 6-6-6-.9.9 6 6-6 6 .9.9 6-6 6 6 .9-.9-6-6 6-6z" />
  </svg>
);

export default SvgClose;
