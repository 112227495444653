// Data
import { useDispatch, useSelector } from 'react-redux'
import { setLoading, displayReconnection, updateAllData, setSaved, setDeviceInfo, setOriginalMeasurement, setRemoteData, setLocationData } from 'redux/actions'

// Capacitor
import { Network } from '@capacitor/network'
import { Device } from '@capacitor/device'

let cachedAssets = []

// Turn remote data fetch on and off
const fetchData = true

const version = process.env.REACT_APP_API_VERSION
const api = `${process.env.REACT_APP_BASEURL}${process.env.REACT_APP_API}`

let requested = false

export function runUpdate(siteId) {
  return fetch(`${api}?version=${version}&siteId=${siteId}`)
}

function addStyle(styles) {
  let css = document.createElement('style')
  css.setAttribute('type', 'text/css')

  if (css.styleSheet) {
    css.styleSheet.cssText = styles
  } else {
    css.appendChild(document.createTextNode(styles))
  }

  document.getElementsByTagName('head')[0].appendChild(css)
}

function addFonts(fonts) {
  let css = document.createElement('style')
  css.setAttribute('type', 'text/css')

  fonts.forEach((font) => {
    console.log(font)
    const style = `@font-face { font-family: '${font.font.title}'; src: url('${font.font.url}') format('woff2'); font-display: swap; }`
    css.appendChild(document.createTextNode(style))
  })

  fonts.forEach((font) => {
    font.tags.forEach((tag) => {
      const style = `${tag} { font-family: '${font.font.title}' }`
      css.appendChild(document.createTextNode(style))
    })
  })

  document.getElementsByTagName('head')[0].appendChild(css)
}

const getUrlsFromString = (text) => {
  const urlRegex = /(https:\/\/((www|staging).pixeldigital.ltd|((staging-)*pixeldigital.imgix.net|imgpixel.co.uk))\/assets\/uploads\/[a-z0-9]+\/[a-z0-9]+\/(?!icons)+[^"\s]+)/g
  const matches = [...text.matchAll(urlRegex)]
  return matches.map((match) => match[0].replace('\\', ''))
}

function Loader() {
  const delay = 500

  const dispatch = useDispatch()
  const site = useSelector((state) => state.data.site)

  const storage = window.localStorage

  const runLoader = async () => {
    // Device Info
    const device = await Device.getInfo()

    // Dirty hack to fix Electron builds
    if (window.hasOwnProperty('Capacitor')) {
      device.platform = window.Capacitor.platform
    }

    //device.platform = 'electron'

    dispatch(setDeviceInfo(device))

    // Network Status
    const status = await Network.getStatus()

    if (!status.connected) {
      dispatch(displayReconnection(true))
      return false
    }

    let plotData = []

    let json = {}
    let locationData = []

    // Load data
    if (fetchData) {
      const response = await fetch(`${api}?version=${version}&siteId=${site.id}`)
      json = await response.json()

      if (json.success) {
        // Hack to add isOpen prop to splashscreens for hiding/showing
        json.data.splash = json.data.splash.map((splash) => {
          splash.isOpen = true
          return splash
        })

        plotData = json.data.modules?.property?.plots
        dispatch(updateAllData(json.data))
        dispatch(setOriginalMeasurement(json.data.modules.property.defaultMeasurementColumn))

        locationData = json.data.modules.location.mapSettings

        // OLD
        //SET DEFAULT MAP STYLES FROM CMS.... (Can be overridden with config file further down...)
        //locationData = json.data.modules.location.mapSettings[0]
        // if(!'idleTimeout' in locationData){
        //   locationData['idleTimeout'] = false
        // }

        dispatch(setLocationData(locationData))
      }

      // Add all assets to service worker cache
      cachedAssets = getUrlsFromString(JSON.stringify(json.data, null, 2))
    }

    // Check for saved plots -
    let saved = await storage.getItem('saved')

    if (!saved) {
      await storage.setItem('saved', JSON.stringify([]))
    } else {
      let clearAll = false

      JSON.parse(saved).forEach((savedPlot) => {
        const plotFound = plotData.findIndex((plot) => plot.id == savedPlot)

        if (plotFound == -1) {
          clearAll = true
        }
      })

      if (clearAll) {
        await storage.setItem('saved', JSON.stringify([]))
        saved = '[]'
      }
    }

    dispatch(setSaved(saved))

    // Load config and set "Remote" and "LocationData" states
    const configResponse = await fetch('/config.json')
    const configJson = await configResponse.json()

    dispatch(setRemoteData(configJson.remote))

    // If config.json contains locationData overrides, include them here...
    if (configJson.hasOwnProperty('locationData')) {
      const mergedLocationData = configJson.locationData.concat(locationData)
      dispatch(setLocationData(mergedLocationData))
    }

    const styles = json.data?.theme?.styles

    if (styles) {
      addStyle(styles)
    }

    const fonts = json.data?.theme?.fonts

    if (fonts) {
      addFonts(fonts)
    }

    setTimeout(() => dispatch(setLoading(false)), delay)

    if (process.env.REACT_APP_OVERSEAS && status.connected) {
      console.log('SHOULD CACHE ALL FILES...')

      // Load all images from the api/project and cache them when connected and in overseas mode.
      setTimeout(() => cachedAssets.forEach(async (asset) => await fetch(asset)), 3000)
    }
  }

  if (!requested) {
    requested = true
    runLoader()
  }

  return null
}

export default Loader
