import * as actionTypes from 'redux/actionTypes'

const initialState = {
  appLoading: true,
  displayReconnection: false,

  totalAssetsToCache: 0,
  totalAssetsCached: 0,
  lastCachedTime: false,

  menuOpen: false,
  contactOpen: false,
  shareOpen: false,
  shareFavouritesOpen: false,
  registerOpen: false,

  filterOpen: false,
  viewOpen: false,
  locationFilterOpen: false,

  screensOpen: false,

  gridView: false,
  compareMode: false,
  compareLeftPlot: null,
  compareRightPlot: null,

  locationSearch: false,

  originalMeasurement: false,

  serviceWorkerInitialized: false,
  serviceWorkerUpdated: false,
  serviceWorkerRegistration: null,

  modelAsideView: 'overview',
  showPlotCompare: false,
  modelComparePlots: [],
  modelCompareIndex: 0,

  modelSavedFloor: -1,

  locationData: [],
  defaultLocationData: {
    idleTimeout: 0,
    zoom: 13, // TODO: Remove this when removed from CMS (kept for old apps)
    startZoom: 5,
    endZoom: 14,
    minZoom: 5.5,
    maxZoom: 17,
    speed: 0.8,
    bearing: 0,
    pitch: 60,
    showMarkers: true,
  },

  specificationCompareMode: false,
  specificationCompare: {},

  enlargedType: null,
  enlargedAssets: [],

  confirmDialogOpen: false,

  disableScreenSaver: false,
}

export default function stateReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_LOADING: {
      const { loading } = action.payload
      return { ...state, appLoading: loading }
    }

    case actionTypes.DISPLAY_RECONNECTION: {
      const { displayReconnection } = action.payload
      return { ...state, displayReconnection }
    }

    case actionTypes.SET_TOTAL_ASSETS_TO_CACHE: {
      const { total } = action.payload
      return { ...state, totalAssetsToCache: total }
    }

    case actionTypes.SET_TOTAL_ASSETS_CACHED: {
      const { count } = action.payload
      return { ...state, totalAssetsCached: count }
    }

    case actionTypes.SET_ASSETS_LAST_CACHED: {
      const { datetime } = action.payload
      return { ...state, lastCachedTime: datetime }
    }

    case actionTypes.DISABLE_SCREEN_SAVER: {
      return { ...state, disableScreenSaver: true }
    }

    // Drop panels
    // Allow only 1 to be open
    case actionTypes.TOGGLE_MENU: {
      const { menuOpen } = action.payload
      return { ...state, menuOpen: menuOpen, contactOpen: false, shareOpen: false, registerOpen: false, shareFavouritesOpen: false }
    }

    case actionTypes.TOGGLE_SHARE: {
      const { shareOpen } = action.payload
      return { ...state, menuOpen: false, contactOpen: false, shareOpen: shareOpen, registerOpen: false, shareFavouritesOpen: false }
    }

    case actionTypes.TOGGLE_SHARE_FAVOURITES: {
      const { shareFavouritesOpen } = action.payload
      return { ...state, menuOpen: false, contactOpen: false, shareOpen: false, registerOpen: false, shareFavouritesOpen: shareFavouritesOpen }
    }

    case actionTypes.TOGGLE_REGISTER: {
      const { registerOpen } = action.payload
      return { ...state, menuOpen: false, contactOpen: false, shareOpen: false, registerOpen: registerOpen, shareFavouritesOpen: false }
    }

    case actionTypes.TOGGLE_CONFIRM_DIALOG: {
      const { confirmDialogOpen } = action.payload
      return { ...state, confirmDialogOpen: confirmDialogOpen }
    }

    case actionTypes.CLOSE_ALL_PANELS: {
      return { ...state, menuOpen: false, contactOpen: false, shareOpen: false, registerOpen: false, shareFavouritesOpen: false }
    }

    // - -

    // Sidebars
    // Allow only 1 to be open
    case actionTypes.TOGGLE_FILTER: {
      const { filterOpen } = action.payload
      return { ...state, filterOpen: filterOpen, viewOpen: false, locationFilterOpen: false }
    }

    case actionTypes.TOGGLE_VIEWMODE: {
      const { viewOpen } = action.payload
      return { ...state, viewOpen: viewOpen, filterOpen: false, locationFilterOpen: false }
    }

    case actionTypes.TOGGLE_LOCATION_FILTER: {
      const { locationFilterOpen } = action.payload
      return { ...state, locationFilterOpen: locationFilterOpen, filterOpen: false, viewOpen: false }
    }

    case actionTypes.CLOSE_ALL_SIDEBARS: {
      return { ...state, viewOpen: false, filterOpen: false, locationFilterOpen: false, screensOpen: false }
    }

    case actionTypes.SET_SHOW_PLOT_COMPARE: {
      const { active } = action.payload
      return { ...state, showPlotCompare: active }
    }

    // case actionTypes.MODEL_ASIDE_VIEW: {
    //   const { view } = action.payload
    //   return { ...state, modelAsideView: view }

    // }

    case actionTypes.TOGGLE_SCREENS: {
      const { screensOpen } = action.payload
      return { ...state, screensOpen: screensOpen }
    }

    // Compare Mode

    case actionTypes.TOGGLE_COMPAREMODE: {
      const { compareMode } = action.payload
      return { ...state, compareMode: compareMode }
    }

    case actionTypes.SET_COMPARE_LEFT_PLOT: {
      const { plot } = action.payload
      return { ...state, compareLeftPlot: plot }
    }

    case actionTypes.SET_COMPARE_RIGHT_PLOT: {
      const { plot } = action.payload
      return { ...state, compareRightPlot: plot }
    }

    // Model Compare Mode

    case actionTypes.SET_MODEL_COMPARE: {
      const { plots } = action.payload
      return { ...state, modelComparePlots: plots }
    }

    case actionTypes.SET_MODEL_COMPARE_INDEX: {
      const { index } = action.payload
      return { ...state, modelCompareIndex: index }
    }

    // Other
    case actionTypes.TOGGLE_GRIDVIEW: {
      const { gridView } = action.payload
      return { ...state, gridView: gridView }
    }

    case actionTypes.SET_LOCATION_SEARCH: {
      const { locationSearch } = action.payload
      return { ...state, locationSearch: locationSearch }
    }

    case actionTypes.SET_ORIGINAL_MEASUREMENT: {
      const { originalMeasurement } = action.payload
      return { ...state, originalMeasurement: originalMeasurement }
    }

    case actionTypes.INIT_SERVICE_WORKER: {
      return { ...state, serviceWorkerInitialized: !state.serviceWorkerInitialized }
    }

    case actionTypes.UPDATE_SERVICE_WORKER: {
      return {
        ...state,
        serviceWorkerUpdated: !state.serviceWorkerUpdated,
        serviceWorkerRegistration: action.payload,
      }
    }

    case actionTypes.SET_IDLE_TIMEOUT: {
      const { idleTimeout } = action.payload
      return { ...state, idleTimeout: idleTimeout }
    }

    case actionTypes.SET_LOCATION_DATA: {
      const { locationData } = action.payload
      return { ...state, locationData: locationData }
    }

    // Model
    case actionTypes.SET_MODEL_SAVED_FLOOR: {
      const { modelSavedFloor } = action.payload
      return { ...state, modelSavedFloor: modelSavedFloor }
    }

    // Specification
    case actionTypes.SET_SPECIFICATION_COMPARE_MODE: {
      const { specificationCompareMode } = action.payload
      return { ...state, specificationCompareMode: specificationCompareMode }
    }

    case actionTypes.SET_SPECIFICATION_COMPARE: {
      const { specificationCompare } = action.payload
      return { ...state, specificationCompare: specificationCompare }
    }

    // Enlarged assets
    case actionTypes.SET_ENLARGED_ASSETS: {
      const { enlargedType, enlargedAssets } = action.payload
      return {
        ...state,
        enlargedType: enlargedType,
        enlargedAssets: enlargedAssets,
      }
    }

    default:
      return state
  }
}
