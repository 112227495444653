import * as React from "react";

const SvgExpandButton = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 25"
    style={{
      enableBackground: "new 0 0 25 25",
    }}
    xmlSpace="preserve"
    width="1em"
    height="1em"
    role="img"
    {...props}
  >
    <path d="M7.8 9.2 2 3.4v4.8H0V0h8.1v2H3.4l5.9 5.9-1.5 1.3zm9.4 0-1.4-1.4L21.6 2h-4.8V0H25v8.1h-2V3.4l-5.8 5.8zM8.1 25H0v-8.1h2v4.8l5.9-5.9 1.4 1.4L3.4 23h4.8v2h-.1zM25 25h-8.1v-2h4.8l-5.9-5.9 1.4-1.4 5.9 5.9v-4.8h2V25H25z" />
  </svg>
);

export default SvgExpandButton;
