// Data
import { useSelector } from 'react-redux'

// Animation
import { motion, AnimatePresence } from 'framer-motion'
import { variants } from 'utils/animation'

// SCSS
import 'components/side-panel.scss'

function SidePanel({ children, trigger, position, title, subtitle, ...props}){

  const deviceInfo = useSelector(state => state.device.info)

  return (

    <AnimatePresence>

      { trigger &&

        <motion.div 
          className={position == 'right' ? 'side-panel-right' : 'side-panel'} 
          animate={trigger ? "open" : "closed"}
          exit={"closed"}
          variants={deviceInfo && deviceInfo.platform === "electron" ? variants.sidebar_bottom : position == 'right' ? variants.sidebar_right : variants.sidebar_left} 
          { ...props }
        >
          { title &&
            <div className="side-panel-title">
              <h2>{title} {subtitle ? <><br />{subtitle}</> : ''}</h2>
            </div>
          }
          <div className="side-panel-scroller">
            {children}
          </div>
        </motion.div>
      
      }

    </AnimatePresence>

  )
  

}


export default SidePanel