import * as React from "react";

const Svg360Button = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 45 30"
    style={{
      enableBackground: "new 0 0 45 30",
    }}
    xmlSpace="preserve"
    width="1em"
    height="1em"
    role="img"
    {...props}
  >
    <path d="M36.3 2.3c0 .3.1.6.3.8.2.2.5.3.8.3s.6-.1.8-.3.3-.5.3-.8-.1-.6-.3-.8-.5-.3-.8-.3-.6.1-.8.3c-.2.2-.3.5-.3.8m-.5 1.5c-.4-.4-.7-1-.7-1.6s.2-1.1.7-1.6c.4-.4 1-.7 1.6-.7.6.1 1.1.3 1.6.8.4.4.7 1 .7 1.6s-.2 1.1-.7 1.6c-.4.4-1 .7-1.6.7-.6-.1-1.1-.3-1.6-.8m-6.7 1.4c0 1.2.1 2 .3 2.6.2.6.6.9 1.2.9s1-.3 1.2-.9c.1-.6.2-1.4.2-2.6s-.1-2.1-.3-2.7c-.2-.6-.6-.9-1.2-.9s-1 .3-1.2.9c-.1.6-.2 1.5-.2 2.7M27.8 9c-.5-.9-.8-2.2-.8-3.8 0-1.7.3-2.9.8-3.8S29.2 0 30.5 0s2.2.5 2.7 1.3c.5 1 .8 2.3.8 3.9 0 1.7-.3 2.9-.8 3.8s-1.4 1.3-2.7 1.3c-1.3.1-2.2-.4-2.7-1.3m-6.7-2.1c0 .5.1 1 .4 1.3.3.3.7.5 1.1.5s.8-.2 1-.5c.4-.2.5-.7.5-1.2 0-.6-.1-1-.4-1.3s-.6-.5-1.1-.5c-.3 0-.6.1-.9.3-.4.3-.6.8-.6 1.4m-1.2 1.9c-.5-.8-.7-1.9-.7-3.2 0-.8 0-1.4.1-1.9.1-.9.3-1.6.7-2.1.3-.5.7-.9 1.1-1.2.5-.3 1-.4 1.7-.4.9 0 1.7.2 2.2.7.6.5.9 1.1.9 1.9h-2c0-.2-.1-.3-.2-.5-.2-.3-.5-.5-1-.5-.6 0-1.1.4-1.4 1.1-.1.4-.2 1-.3 1.8.3-.3.6-.5 1-.7.3-.1.7-.2 1.1-.2.9 0 1.6.3 2.2.9s.9 1.4.9 2.3-.3 1.8-.8 2.5c-.6.7-1.4 1.1-2.6 1.1-1.4 0-2.3-.5-2.9-1.6m-5.3 0c-.6 0-1.1-.2-1.3-.7-.1-.3-.2-.6-.2-1h-1.9c0 .8.2 1.4.5 1.9.6.9 1.5 1.4 3 1.4 1.2 0 2-.3 2.6-1 .6-.6.9-1.4.9-2.2s-.3-1.4-.8-1.9c-.3-.3-.6-.5-.8-.5.2-.1.5-.3.7-.5.4-.4.5-.9.5-1.5 0-.8-.3-1.5-.9-2-.5-.5-1.3-.8-2.3-.8-.5 0-1 .1-1.3.2-.4.1-.7.3-.9.5-.3.3-.6.7-.8 1.1s-.2.9-.3 1.4h1.8c0-.5.1-.9.3-1.2s.6-.4 1-.4.7.1.9.4c.2.2.3.6.3.9 0 .6-.2 1-.7 1.2-.3.1-.7.2-1.3.2v1.4c.7 0 1.1.1 1.4.2.5.2.8.7.8 1.3 0 .5-.1.9-.4 1.1 0 .3-.4.5-.8.5M37.4 6v3.2s1.9 1.5 1.5 3.8c-.3 1.9-3.4 4.4-9.2 5.6l7.6 4.8c3.9-1.7 7.3-4.4 7.7-8.6 0 .1.5-5.6-7.6-8.8m-18.2 9.8 11.2 7.1L19.2 30v-3.9l-.2.2S.8 24.7 0 14.9c0 0-.5-5.7 7.5-8.9v3.2S5.6 10.7 6 13s4.8 5.5 13 6.1l.1.1v-3.4h.1z" />
  </svg>
);

export default Svg360Button;
