import * as React from "react";

const SvgSecondScreenActive = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 39 33"
    style={{
      enableBackground: "new 0 0 39 33",
    }}
    xmlSpace="preserve"
    width="1em"
    height="1em"
    role="img"
    {...props}
  >
    <path d="M0 0v23h39V0H0zm15.6 18.8-5.1-5.1 1.4-1.4 3.7 3.7L27.1 4.3l1.4 1.4-12.9 13.1zM13.5 33h12l-6-7z" />
  </svg>
);

export default SvgSecondScreenActive;
