// Core
import { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

// Hooks
import { useController } from 'utils/controller'

// Data
import { useDispatch } from 'react-redux'
import { setEnlargedAssets } from 'redux/actions'

// Custom
import IconButton from 'components/icon-button'
import Image from 'components/image'

// Vendor
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination, Zoom } from 'swiper'
import 'swiper/swiper-bundle.css'

// Icons
import GalleryArrowLeft from 'icons/GalleryArrowLeft'
import GalleryArrowRight from 'icons/GalleryArrowRight'
import RotatePrompt from 'icons/RotatePrompt'
import ExpandButton from 'icons/ExpandButton'

import 'components/image-gallery.scss'

SwiperCore.use([Navigation, Pagination, Zoom])

function ImageGallery({ images, withZoom = false, galleryKey = 0, largeImages = [] }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const controller = useController()

  const prevRef = useRef(null)
  const nextRef = useRef(null)

  const showNav = images.length > 1
  const showGradient = images.length > 1 || images[0].disclaimer

  const [activeDisclaimer, setActiveDisclaimer] = useState(images[0].disclaimer)
  const [swiper, setSwiper] = useState(null)

  useEffect(() => {
    if (swiper) {
      controller.on(`updateGalleryIndex${galleryKey.toString()}Response`, ({ index }) => {
        swiper.slideTo(index)
      })
    }

    controller.on('enlargeAssetsResponse', ({ enlargedType, enlargedAssets }) => {
      dispatch(
        setEnlargedAssets({
          enlargedType: enlargedType,
          enlargedAssets: enlargedAssets,
        })
      )
    })
  }, [controller.socket, swiper])

  const enlargeAssets = () => {
    controller.send({
      action: 'enlargeAssets',
      enlargedType: 'gallery',
      enlargedAssets: largeImages.length > 0 ? largeImages : images,
    })

    dispatch(
      setEnlargedAssets({
        enlargedType: 'gallery',
        enlargedAssets: largeImages.length > 0 ? largeImages : images,
      })
    )

    setTimeout(() => {
      history.push('/enlarge')
    })
  }

  return (
    <Swiper
      onSwiper={(swiper) => setSwiper(swiper)}
      spaceBetween={0}
      slidesPerView={1}
      onSlideChange={(slide) => {
        setActiveDisclaimer(images[slide.activeIndex].disclaimer)

        controller.send({
          action: `updateGalleryIndex${galleryKey.toString()}`,
          index: slide.activeIndex,
        })
      }}
      simulateTouch={false}
      onResize={(swiper) => {
        setTimeout(() => {
          swiper.init()
          swiper.slideTo(0)
        }, 100)
      }}
      zoom={true}
      pagination={
        showNav
          ? {
              type: 'fraction',
              clickable: true,
            }
          : false
      }
      navigation={{
        prevEl: prevRef.current ? prevRef.current : undefined,
        nextEl: nextRef.current ? nextRef.current : undefined,
      }}
      onInit={(swiper) => {
        swiper.params.navigation.prevEl = prevRef.current
        swiper.params.navigation.nextEl = nextRef.current
        swiper.navigation.update()
      }}
    >
      {images.map((image, key) => (
        <SwiperSlide key={key} className={showGradient && 'gradient'}>
          <div className="swiper-zoom-container">
            <Image src={image.url} alt={image.disclaimer} />
          </div>
        </SwiperSlide>
      ))}

      <span className="gallery-extras" slot="container-end">
        {showNav && (
          <div className="gallery-nav">
            <div ref={prevRef} className="cursor-pointer">
              <GalleryArrowLeft />
            </div>
            <div ref={nextRef} className="cursor-pointer">
              <GalleryArrowRight />
            </div>
          </div>
        )}

        <p>{activeDisclaimer}</p>
      </span>

      {withZoom && (
        <IconButton extraClass="gallery-zoom-button" onClick={() => enlargeAssets()}>
          <ExpandButton />{' '}
        </IconButton>
      )}

      <span className="rotate">
        <RotatePrompt />
      </span>
    </Swiper>
  )
}

ImageGallery.defaultProps = {
  images: [],
}

export default ImageGallery
