// CSS
import 'components/icon-button.scss';

function IconButton({ backgroundColor, color, size, active = false, extraClass, children, ...props }) {

  return (
    <button
      type="button"
      className={['icon-button', `icon-button--${size}`, `${active ? 'active': ''}`, `${extraClass ? extraClass : ''}`].join(' ')}
      style={{ backgroundColor, color } }
      {...props}
    >
      { children }
    </button>
  );

};

// Default Component Properties
IconButton.defaultProps = {
  backgroundColor: null,
  color: null,
  size: 'regular',
  onClick: undefined,
};

export default IconButton;