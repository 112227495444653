import { lazy, Suspense } from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'

import Grid from 'modules/grid/grid'

// View Mapping
const views = {
  // Grid
  grid: Grid,

  // EntryTypes
  magazine: lazy(() => import(`modules/magazine/magazine`)),
  gallery: lazy(() => import(`modules/gallery/gallery`)),
  specification: lazy(() => import(`modules/specification/specification`)),
  interactiveImage: lazy(() => import(`modules/interactive/interactive`)),

  // Property Module
  property: lazy(() => import(`modules/property/property`)),
  categories: lazy(() => import(`modules/property/categories`)),
  schedule: lazy(() => import(`modules/property/schedule`)),
  locator: lazy(() => import(`modules/property/locator`)),
  floorplan: lazy(() => import(`modules/property/floorplan`)),
  plot_detail: lazy(() => import(`modules/property/plot-detail`)),
  siteplan: lazy(() => import(`modules/property/siteplan`)),

  // Location Module
  location: lazy(() => import(`modules/location/location`)),

  // Contact Module
  contact: lazy(() => import(`modules/contact/contact`)),
}

const multiViewEntryTypes = ['specification']

function ComponentLoader({ page }) {
  const location = useLocation()
  const Component = page.module ? views[page.module] : views[page.type]

  return (
    <Switch location={location} key={location.pathname}>
      <Route key={`view-${page.slug}`} path={`/${page.uri}`} exact={!page.module && !multiViewEntryTypes.includes(page.type)}>
        <Suspense fallback={<div>Loading...</div>}>{page.children && page.children.length > 0 ? <Grid children={page.children} /> : <Component page={page} />}</Suspense>
      </Route>

      {page.children &&
        page.children.length > 0 &&
        page.children.map((child) => {
          return (
            <Route key={`view-${child.slug}`} path={`/${child.uri}`}>
              <ComponentLoader page={child} />
            </Route>
          )
        })}
    </Switch>
  )
}

export default ComponentLoader
