// Core
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Data
import { useDispatch } from 'react-redux';
import { setEnlargedAssets } from 'redux/actions'

// Custom
import Image from 'components/image'
import IconButton from 'components/icon-button';
import EmbedGallery from 'components/embed-gallery';

// Icons
import PlayButton from 'icons/PlayButton'
import ExpandButton from 'icons/ExpandButton'

// Video fallback
import videoFallbackImage from 'video-fallback.jpg'

import { Network } from '@capacitor/network';

// CSS
import 'components/video-embed.scss'


function VideoEmbed({ embed, thumbnail, showButton = false, withZoom = false }) {

  const dispatch = useDispatch()
  const history = useHistory()

  const [trigger, setTrigger] = useState(false)
  const [networkStatus, setNetworkStatus] = useState({})

  useEffect(() => {
    
    const getNetwork = async () => {

      const status = await Network.getStatus()
      setNetworkStatus(status)

    } 

    getNetwork()

  }, [])

  const enlargeAssets = () => {

    dispatch( setEnlargedAssets({
      enlargedType: 'videoEmbed',
      enlargedAssets: [embed]
    }) )

    setTimeout(() => history.push('/enlarge'))

  }

  return (

    <div className="video-embed">

      { networkStatus.connected 

        ? <>

          { showButton 
          
            ? trigger 
            
              ? <EmbedGallery asset={embed} />
            
              : <div className="video-embed-placeholder">
                  <Image src={thumbnail} />
                
                  <IconButton onClick={() => setTrigger(true)}>
                    <PlayButton />
                  </IconButton>
                </div>

            : <EmbedGallery asset={embed} />
                
          }

          { withZoom && 
            <IconButton extraClass="video-zoom-button" onClick={() => enlargeAssets()}>
              <ExpandButton />
            </IconButton>
          }

        </>

        : <Image className="video-fallback" src={videoFallbackImage} />
      }

    </div>

  )

}

export default VideoEmbed