// Core
import { useState } from 'react'

// Data
import { useDispatch, useSelector } from 'react-redux'
import { closeAllPanels } from 'redux/actions'

// Animation
import { AnimatePresence, motion } from 'framer-motion'

// Forms
import { useForm } from 'react-hook-form'

// Custom
import Button from 'components/button'

// Icons
import FormTick from 'icons/FormTick'

import 'components/form.scss'

const base = process.env.REACT_APP_BASEURL

function Form({ formHandle, submitButtonText, sharePlot, extraFieldData = [], disabled = false }) {
  const [sent, setSent] = useState(false)

  const { register, handleSubmit, errors } = useForm()

  const dispatch = useDispatch()

  const form = useSelector(({ data }) => data.form[formHandle])
  const siteId = useSelector(({ data }) => data.site.id)

  const onSubmit = (payload) => {
    console.log(payload)

    let fd = new FormData()

    fd.append('action', `formie/submissions/submit`)
    fd.append('handle', form.handle)
    fd.append('siteId', siteId)
    fd.append('pageIndex', '')

    Object.keys(payload.fields).forEach((key) => {
      // TODO: Look for a better way to pass plots
      if (key === 'plot') {
        payload.fields[key].forEach((k) => {
          fd.append(`fields[${key}][]`, k)
        })
      } else {
        fd.append(`fields[${key}]`, payload.fields[key])
      }
    })

    if (extraFieldData.length > 0) {
      console.log('EXTRA FIELD DATA', extraFieldData)
      extraFieldData.forEach((extraField) => {
        fd.append(`fields[${extraField.name}]`, extraField.value)
      })
    }

    var myHeaders = new Headers()
    myHeaders.append('X-Requested-With', 'XMLHttpRequest')
    myHeaders.append('Accept', 'application/json')

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: fd,
      redirect: 'follow',
    }

    // DEBUG - - -

    // for (const pair of fd.entries()) {
    //   console.log(`${pair[0]}, ${pair[1]}`)
    // }

    // return false

    // - - -

    fetch(`${base}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setSent(true)

          setTimeout(() => {
            dispatch(closeAllPanels())
            setSent(false)
          }, 3000)
        } else {
          console.error(result.errorMessage)
        }
      })
      .catch((error) => console.log('error', error))
    // TODO: Better error handling here...
  }

  // Unable to get form json
  if (!form) {
    return 'Unable to find form'
  }

  return (
    <AnimatePresence exitBeforeEnter>
      {sent && (
        <motion.div animate={{ opacity: 1 }} initial={{ opacity: 0 }} exit={{ opacity: 0 }} className="form-success">
          <h2>Thank you!</h2>
          {/* <h4>Your message was sent successfully, someone from the Fizzy Living team will be in touch.</h4> */}
          <h4>{form.settings.submitActionMessageCustom}</h4>
        </motion.div>
      )}

      {!sent && (
        <motion.form
          animate={{ opacity: 1 }}
          initial={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={['drop-panel-form', disabled ? 'form-disabled' : ''].join(' ')}
          onSubmit={handleSubmit(onSubmit)}
        >
          {sharePlot && sharePlot.map((plot, index) => <input key={index} type="hidden" name={`fields[plot].${index}`} value={plot} ref={register} />)}

          {/* TODO: Move fields to separate component */}
          {form.fields.map((field, key) => {
            const { name, handle, required, displayName, description, instructions, ...attrs } = field
            // Leave type, required and placeholder to be mapped via attrs

            const fieldKey = handle.replace('fields[', '').replace(']', '')

            switch (field.type) {
              case 'hidden':
                return null

              case 'checkbox':
                return (
                  <div key={key} className="field checkbox">
                    <label htmlFor={handle}>
                      <span>
                        <input name={handle} {...attrs} ref={register({ required: field.required })} />
                        <span className="check">
                          <FormTick />
                        </span>
                      </span>
                      {description && <div dangerouslySetInnerHTML={{ __html: description }} />}
                    </label>
                    {errors && errors.fields && errors.fields[fieldKey] ? <i>{field.name} is required</i> : ''}
                  </div>
                )

              case 'select':
                return (
                  <div key={key} className="field select">
                    <label htmlFor={handle}>{field.name}</label>
                    <select name={handle} ref={register({ required: field.required })}>
                      {field.options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    {instructions && <small>{instructions}</small>}
                    {errors && errors.fields && errors.fields[fieldKey] ? <i>{field.name} is required</i> : ''}
                  </div>
                )

              default:
                return (
                  <div key={key} className={['field', field.type].join(' ')}>
                    <label htmlFor={handle}>{field.name}</label>
                    <input name={handle} {...attrs} ref={register({ required: field.required })} />
                    {instructions && <small>{instructions}</small>}
                    {errors && errors.fields && errors.fields[fieldKey] ? <i>{field.name} is required</i> : ''}
                  </div>
                )
            }
          })}

          <Button type="submit" label={submitButtonText} />
        </motion.form>
      )}
    </AnimatePresence>
  )
}

export default Form
