// Data
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedDevice, toggleScreenSelector } from "redux/actions"

// Hooks
import { useController } from 'utils/controller'

// Generic Components
import Button from 'components/button'
import { useEffect } from 'react'
import SidePanel from 'components/side-panel'

function ScreenSelector() {

  const dispatch = useDispatch()
  const controller = useController()

  const { state, remote } = useSelector(({ device, state, remote }) => ({ device, state, remote }))
  const { screensOpen } = state
  const { connectedDevices, selectedDevice } = remote

  const pairWithScreen = (remoteDevice) => {

    if(selectedDevice){
      // If currently connected to one device - close the connection before switching.
      controller.switchDevice({
        selectedChannel: selectedDevice.channel,
        action: 'goToPage',
        pathname: '/', 
        showSplash: true, 
        hideSplash: false
      })

    }

    dispatch( setSelectedDevice(remoteDevice) )

    setTimeout(() => {
      dispatch( toggleScreenSelector(false) )
    }, 1000)
    
  }
  
  const disconnectFromScreen = () => {
  
    controller.send({
      action: 'goToPage',
      pathname: '/', 
      showSplash: true, 
      hideSplash: false
    })

    dispatch( setSelectedDevice(null) )
    dispatch( toggleScreenSelector(false) )

  }

  useEffect(() => {

    if(selectedDevice){
    
      controller.send({
        action: 'goToPage',
        pathname: window.location.pathname, 
        showSplash: false, 
        hideSplash: true
      })
      
    }

  }, [selectedDevice])

  return (

    <SidePanel trigger={screensOpen} position="right" id="screen-selector" title="SCREENS">

      <div className="filter-group">

        {/* TODO: filter by name - only show 1 connection button for each name */}

        { controller.connected
          ? <>
              { Object.keys(connectedDevices).map((key) => {
              
                if(connectedDevices[key].channel == controller.remoteData.channel){
                  return null
                }

                return (
                  <Button 
                    key={key} 
                    label={connectedDevices[key].channel} 
                    active={selectedDevice && selectedDevice.channel == connectedDevices[key].channel } 
                    onClick={() => pairWithScreen(connectedDevices[key])} 
                  />
                )
              }) }

              { selectedDevice &&
                <Button label="Disconnect" onClick={() => disconnectFromScreen()} />
              }
            </>

          : <>
              <p>You are not currently connected to a server</p>
              <Button label="Reconnect" onClick={() => controller.connect() } />
            </>
        }

      </div>

    </SidePanel>

  )

}

export default ScreenSelector