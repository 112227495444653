// Core
import { useContext } from 'react'

// Context
import { AppContext } from 'utils/context'

// Custom
import ReactiveGrid, { ReactiveGridItem } from 'components/reactive-grid'
import Scene from 'components/scene'

function Grid({ children }) {
  const appContext = useContext(AppContext)

  const filteredChildren = children.filter((child) => {
    if (child.visibility == 'hidden') {
      return false
    }

    if (child.visibility == 'public' && appContext !== 'public') {
      return false
    }

    if (child.visibility == 'sales' && appContext !== 'marketing-suite') {
      return false
    }

    return true
  })

  return (
    <Scene>
      <ReactiveGrid>
        {filteredChildren.map((panel, key) => (
          <ReactiveGridItem
            key={key}
            index={key}
            location={panel.type == 'link' ? `${panel.linkUrl}` : `/${panel.uri}`}
            newWindow={panel.type == 'link' && panel.hasOwnProperty('openInNewTab') && panel.openInNewTab}
            src={panel.featuredImage ? panel.featuredImage.url : 'https://via.placeholder.com/150'}
            video={panel.featuredVideoUrl ? panel.featuredVideoUrl : false}
            title={panel.title}
          />
        ))}
      </ReactiveGrid>
    </Scene>
  )
}

export default Grid
