const baseUrl = process.env.REACT_APP_BASEURL

function useShare() {
  const generateCode = async (siteId, plotIds) => {
    const options = {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ siteId, plotIds: plotIds }),
    }

    const res = await fetch(`${baseUrl}/favourites/generate-code`, options)
    const json = await res.json()

    return json
  }

  const retrievePlotList = async (code) => {
    const options = {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ code: code }),
    }

    const res = await fetch(`${baseUrl}/favourites/retrieve-code`, options)
    const json = await res.json()

    return json
  }

  return { generateCode, retrievePlotList }
}

export default useShare
