// Core
import { useEffect, useState } from 'react'

// Data
import { useDispatch, useSelector } from 'react-redux'
import { updateServiceWorker, disableScreenSaver } from './redux/actions'

// Layouts
import LoadingLayout from 'layouts/loading-layout'
import AppLayout from 'layouts/app-layout'

// Components
import Alert from 'components/alert'

// Utils
import { AppContextProvider } from 'utils/context'
import { useLocation } from 'react-router-dom'

// Capacitor
import { Network } from '@capacitor/network'

// CSS
import 'App.scss'

function App() {
  const location = useLocation()
  const dispatch = useDispatch()
  const appLoading = useSelector(({ state }) => state.appLoading)
  const [networkStatus, setNetworkStatus] = useState({})

  useEffect(() => {
    const getNetwork = async () => {
      const status = await Network.getStatus()
      setNetworkStatus(status)
    }

    getNetwork()

    if (location.search.includes('screensaver=false')) {
      dispatch(disableScreenSaver())
    }
  }, [])

  // const isServiceWorkerInitialized = useSelector(({ state }) => state.serviceWorkerInitialized)

  const isServiceWorkerUpdated = useSelector(({ state }) => state.serviceWorkerUpdated)

  const serviceWorkerRegistration = useSelector(({ state }) => state.serviceWorkerRegistration)

  const runServiceWorkerUpdate = () => {
    const registrationWaiting = serviceWorkerRegistration.waiting

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' })

      registrationWaiting.addEventListener('statechange', (e) => {
        if (e.target.state === 'activated') {
          window.location.reload()
        }
      })
    }
  }

  return (
    <AppContextProvider>
      <LoadingLayout visible={appLoading} status={networkStatus} />
      <AppLayout visible={!appLoading} status={networkStatus} />

      {/* 
        {isServiceWorkerInitialized && (
          <Alert text="Service Worker is initialized for the first time" fn={initServiceWorker} />
        )} 
      */}

      {isServiceWorkerUpdated && <Alert text="There is a new version available." buttonText="Update Now" fn={updateServiceWorker} onClick={() => runServiceWorkerUpdate()} />}
    </AppContextProvider>
  )
}

export default App
