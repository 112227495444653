import * as React from "react";

const SvgHamburger = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 39 22"
    width="1em"
    height="1em"
    role="img"
    {...props}
  >
    <path d="M39 2H0V0h39v2zm0 8H0v2h39v-2zm0 10H0v2h39v-2z" />
  </svg>
);

export default SvgHamburger;
