// Core
import { useContext } from 'react'
import { useHistory } from 'react-router-dom'

// Context
import { AppContext } from 'utils/context'

// Data
import { useDispatch, useSelector } from 'react-redux'
import {
  toggleMenu,
  toggleShare,
  toggleShareFavourites,
  toggleConfirmDialog,
  closeAllPanels,
  toggleFilter,
  toggleViewMode,
  toggleLocationFilter,
  toggleScreenSelector,
  closeAllSidebars,
} from 'redux/actions'

// Animation
import { motion } from 'framer-motion'
import { variants } from 'utils/animation'

// Custom
import IconButton from 'components/icon-button'

// Icons
import BackButton from 'icons/BackButton'
import Close from 'icons/Close'
import Hamburger from 'icons/Hamburger'
import Share from 'icons/Share'
import Filter from 'icons/Filter'
//import ViewMode from 'icons/ViewMode'
import SecondScreenActive from 'icons/SecondScreenActive'
import SecondScreenInactive from 'icons/SecondScreenInactive'

function HeaderLinks({ side, slug }) {
  const history = useHistory()
  const dispatch = useDispatch()

  const appContext = useContext(AppContext)

  const {
    property,
    menuOpen,
    shareOpen,
    shareFavouritesOpen,
    registerOpen,
    showShareBrochureForm,
    screensOpen,
    filterOpen,
    viewOpen,
    locationFilterOpen,
    compareLeftPlot,
    compareRightPlot,
    mapIntegration,
    socket,
    deviceInfo,
  } = useSelector(({ state, remote, data, device }) => ({
    property: data.modules.property,
    menuOpen: state.menuOpen,
    shareOpen: state.shareOpen,
    shareFavouritesOpen: state.shareFavouritesOpen,
    registerOpen: state.registerOpen,

    showShareBrochureForm: data.modules.property.showShareBrochureForm,

    screensOpen: state.screensOpen,

    filterOpen: state.filterOpen,
    viewOpen: state.viewOpen,

    locationFilterOpen: state.locationFilterOpen,
    compareLeftPlot: state.compareLeftPlot,
    compareRightPlot: state.compareRightPlot,

    mapIntegration: data.modules.location.mapIntegration,

    socket: remote.socket,
    deviceInfo: device.info,
  }))

  const anyPanelOpen = menuOpen || shareOpen || shareFavouritesOpen || registerOpen

  const toggleDeviceScreen = () => {
    if (deviceInfo.platform !== 'electron') {
      dispatch(toggleScreenSelector(!screensOpen))
    }
  }

  const allButtons = {
    back: (
      <IconButton onClick={() => history.goBack()}>
        <BackButton />
      </IconButton>
    ),
    backAndCloseSidebars: (
      <IconButton
        onClick={() => {
          history.goBack()
          dispatch(closeAllSidebars())
        }}
      >
        <BackButton />
      </IconButton>
    ),
    closePanels: (
      <IconButton onClick={() => dispatch(closeAllPanels())}>
        <Close />
      </IconButton>
    ),
    closeBack: (
      <IconButton onClick={() => history.goBack()}>
        <Close />
      </IconButton>
    ),

    menu: (
      <IconButton onClick={() => dispatch(toggleMenu(true))}>
        <Hamburger />
      </IconButton>
    ),
    share: (
      <IconButton onClick={() => dispatch(toggleShare(true))}>
        <Share />
      </IconButton>
    ),

    shareFavourites: (
      <IconButton onClick={() => dispatch(toggleShareFavourites(true))}>
        <Share />
      </IconButton>
    ),

    // viewMode: <></>, // <IconButton onClick={() => dispatch( toggleViewMode(!viewOpen) )} active={ viewOpen }><ViewMode /></IconButton>,
    propertyFilter: (
      <IconButton onClick={() => dispatch(toggleFilter(!filterOpen))} active={filterOpen}>
        <Filter />
      </IconButton>
    ),
    locationFilter: (
      <IconButton onClick={() => dispatch(toggleLocationFilter(!locationFilterOpen))} active={locationFilterOpen}>
        <Filter />
      </IconButton>
    ),

    screens: (
      <IconButton onClick={() => toggleDeviceScreen()} active={screensOpen}>
        {socket && socket.connected ? <SecondScreenActive /> : <SecondScreenInactive />}
      </IconButton>
    ),
    closeApp: (
      <IconButton onClick={() => dispatch(toggleConfirmDialog(true))}>
        <Close />
      </IconButton>
    ),
  }

  // Left Nav - -
  const defaultLeftNav = anyPanelOpen ? null : allButtons.back

  const defaultLeftNavTV = (
    <>
      {anyPanelOpen ? null : (
        <>
          {deviceInfo.platform == 'electron' && process.env.REACT_APP_OVERSEAS && <> {allButtons.closeApp} </>}

          {appContext == 'marketing-suite' && process.env.REACT_APP_REMOTE_SERVER && <> {allButtons.screens} </>}
        </>
      )}
    </>
  )

  const scheduleLeftNav = (
    <>
      {anyPanelOpen ? null : (
        <>
          {allButtons.backAndCloseSidebars}
          {allButtons.propertyFilter}
          {appContext === 'marketing-suite' && property.showViewModeMenu && <> {allButtons.viewMode} </>}
        </>
      )}
    </>
  )

  const scheduleLeftNavTV = (
    <>
      {anyPanelOpen ? null : (
        <>
          {deviceInfo.platform == 'electron' && process.env.REACT_APP_OVERSEAS && <> {allButtons.closeApp} </>}

          {appContext == 'marketing-suite' && process.env.REACT_APP_REMOTE_SERVER && <> {allButtons.screens} </>}

          {showShareBrochureForm && appContext === 'marketing-suite' && !compareLeftPlot && !compareRightPlot && <> {allButtons.shareFavourites} </>}
        </>
      )}
    </>
  )

  const locationLeftNav = (
    <>
      {anyPanelOpen ? null : (
        <>
          {allButtons.backAndCloseSidebars}
          {(mapIntegration === 'google' || appContext === 'public') && <> {allButtons.locationFilter} </>}
        </>
      )}
    </>
  )

  // Right Nav - -
  const defaultRightNav = (
    <>
      {anyPanelOpen ? (
        allButtons.closePanels
      ) : (
        <>
          {appContext === 'public' && (
            <>
              {' '}
              {allButtons.share} {allButtons.menu}{' '}
            </>
          )}

          {deviceInfo.platform == 'electron' && process.env.REACT_APP_OVERSEAS && <> {allButtons.closeApp} </>}

          {appContext == 'marketing-suite' && process.env.REACT_APP_REMOTE_SERVER && <> {allButtons.screens} </>}
        </>
      )}
    </>
  )

  const defaultRightNavTV = <>{anyPanelOpen ? allButtons.closePanels : allButtons.backAndCloseSidebars}</>

  const detailRightNav = (
    <>
      {anyPanelOpen ? (
        allButtons.closePanels
      ) : (
        <>
          {appContext === 'public' && (
            <>
              {' '}
              {allButtons.share} {allButtons.menu}{' '}
            </>
          )}

          {appContext === 'marketing-suite' && <> {allButtons.share} </>}

          {deviceInfo.platform == 'electron' && process.env.REACT_APP_OVERSEAS && <> {allButtons.closeApp} </>}

          {appContext === 'marketing-suite' && process.env.REACT_APP_REMOTE_SERVER && <> {allButtons.screens} </>}
        </>
      )}
    </>
  )

  const detailRightNavTV = (
    <>
      {anyPanelOpen ? (
        allButtons.closePanels
      ) : (
        <>
          {appContext === 'public' && (
            <>
              {' '}
              {allButtons.share} {allButtons.menu}{' '}
            </>
          )}

          {appContext === 'marketing-suite' && <> {allButtons.share} </>}

          <> {allButtons.backAndCloseSidebars} </>
        </>
      )}
    </>
  )

  const scheduleRightNav = (
    <>
      {anyPanelOpen ? (
        allButtons.closePanels
      ) : (
        <>
          {appContext === 'public' && (
            <>
              {' '}
              {allButtons.share} {allButtons.menu}{' '}
            </>
          )}

          {appContext === 'marketing-suite' && (compareLeftPlot || compareRightPlot) && <> {allButtons.share} </>}

          {showShareBrochureForm && appContext === 'marketing-suite' && !compareLeftPlot && !compareRightPlot && <> {allButtons.shareFavourites} </>}

          {deviceInfo.platform == 'electron' && process.env.REACT_APP_OVERSEAS && <> {allButtons.closeApp} </>}

          {appContext === 'marketing-suite' && process.env.REACT_APP_REMOTE_SERVER && <> {allButtons.screens} </>}
        </>
      )}
    </>
  )

  const scheduleRightNavTV = (
    <>
      {anyPanelOpen ? (
        allButtons.closePanels
      ) : (
        <>
          {appContext === 'marketing-suite' && property.showViewModeMenu && <> {allButtons.viewMode} </>}
          {allButtons.propertyFilter}
          {allButtons.backAndCloseSidebars}
        </>
      )}
    </>
  )

  const modelCompareRightNav = (
    <>
      {anyPanelOpen ? (
        allButtons.closePanels
      ) : (
        <>
          {allButtons.share}
          {allButtons.closeBack}
        </>
      )}
    </>
  )

  // STANDARD MENUS (WEB / APP)

  const getLeftNav = (slug) => {
    if (slug === '/') return deviceInfo.platform == 'electron' ? defaultLeftNavTV : null

    if (slug.includes('/model-compare')) return null
    if (slug.includes('/model/')) return deviceInfo.platform == 'electron' ? scheduleLeftNavTV : scheduleLeftNav
    if (slug.includes('/model')) return deviceInfo.platform == 'electron' ? defaultLeftNavTV : defaultLeftNav

    if (slug.includes('/schedule')) return deviceInfo.platform == 'electron' ? scheduleLeftNavTV : scheduleLeftNav
    if (slug.includes('/locator')) return deviceInfo.platform == 'electron' ? defaultLeftNavTV : scheduleLeftNav
    if (slug.includes('/siteplan')) return deviceInfo.platform == 'electron' ? defaultLeftNavTV : scheduleLeftNav

    if (slug.includes('/location')) return deviceInfo.platform == 'electron' ? defaultLeftNavTV : locationLeftNav
    if (slug.includes('/floorplan')) return null
    if (slug.includes('/enlarge')) return deviceInfo.platform == 'electron' ? allButtons.closeBack : null

    return deviceInfo.platform == 'electron' ? defaultLeftNavTV : defaultLeftNav
  }

  const getRightNav = (slug) => {
    if (slug === '/') return deviceInfo.platform == 'electron' ? null : defaultRightNav

    if (slug.includes('/model-compare')) return modelCompareRightNav
    if (slug.includes('/model/')) return deviceInfo.platform == 'electron' ? scheduleRightNavTV : scheduleRightNav
    if (slug.includes('/model')) return deviceInfo.platform == 'electron' ? defaultRightNavTV : defaultRightNav

    if (slug.includes('/schedule')) return deviceInfo.platform == 'electron' ? scheduleRightNavTV : scheduleRightNav
    if (slug.includes('/siteplan')) return null
    if (slug.includes('/locator')) return null

    if (slug.includes('/detail')) return deviceInfo.platform == 'electron' ? detailRightNavTV : detailRightNav
    if (slug.includes('/floorplan')) return allButtons.closeBack
    if (slug.includes('/enlarge')) return deviceInfo.platform == 'electron' ? null : allButtons.closeBack

    return deviceInfo.platform == 'electron' ? defaultRightNavTV : defaultRightNav
  }

  return (
    <motion.div initial="initial" animate="in" exit="out" variants={variants.nav} transition={{ duration: 0 }}>
      {side === 'left' && getLeftNav(slug)}
      {side === 'right' && getRightNav(slug)}
    </motion.div>
  )
}

export default HeaderLinks
