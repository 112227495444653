// Animtation
import { AnimatePresence, motion } from 'framer-motion'
import { variants } from 'utils/animation'

import 'layouts/loading-layout.scss'
import { useSelector } from 'react-redux'

function LoadingLayout({ visible }) {
  const displayReconnection = useSelector(({ state }) => state.displayReconnection)

  return (
    <AnimatePresence exitBeforeEnter>
      {visible && (
        <motion.div key="loading-screen" className="loading-screen" initial={{ opacity: 1 }} exit={{ opacity: 0 }}>
          {!displayReconnection && (
            <div className="spinner">
              <motion.span animate={{ rotate: 360 }} transition={variants.spinner} />
            </div>
          )}
          <h3>Pixel Digital</h3>

          {displayReconnection ? (
            <>
              <h4>You are not connected to the internet</h4>
              <button className="button" onClick={() => window.location.reload()}>
                RELOAD
              </button>
            </>
          ) : (
            <h4>Loading...</h4>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default LoadingLayout
