// Remote
export const SET_SOCKET = 'SET_SOCKET'
export const SET_LIGHTING_SOCKET = 'SET_LIGHTING_SOCKET'
export const SET_CONNECTED_DEVICES = 'SET_CONNECTED_DEVICES'
export const SET_SELECTED_DEVICE = 'SET_SELECTED_DEVICE'
export const SET_REMOTE_DATA = 'SET_REMOTE_DATA'
export const SET_LOCATION_DATA = 'SET_LOCATION_DATA'
export const SET_IDLE_TIMEOUT = 'SET_IDLE_TIMEOUT'
export const DISABLE_SCREEN_SAVER = 'DISABLE_SCREEN_SAVER'

// Device
export const SET_DEVICE_INFO = 'SET_DEVICE_INFO'
export const SET_DEVICE_PLATFORM = 'SET_DEVICE_PLATFORM'

// State
export const SET_LOADING = 'SET_LOADING'
export const DISPLAY_RECONNECTION = 'DISPLAY_RECONNECTION'

export const SET_TOTAL_ASSETS_TO_CACHE = 'SET_TOTAL_ASSETS_TO_CACHE'
export const SET_TOTAL_ASSETS_CACHED = 'SET_TOTAL_ASSETS_CACHED'
export const SET_ASSETS_LAST_CACHED = 'SET_ASSETS_LAST_CACHED'

export const TOGGLE_MENU = 'TOGGLE_MENU'
export const TOGGLE_SHARE = 'TOGGLE_SHARE'
export const TOGGLE_SHARE_FAVOURITES = 'TOGGLE_SHARE_FAVOURITES'
export const TOGGLE_REGISTER = 'TOGGLE_REGISTER'
export const TOGGLE_CONFIRM_DIALOG = 'TOGGLE_CONFIRM_DIALOG'
export const CLOSE_ALL_PANELS = 'CLOSE_ALL_PANELS'

export const TOGGLE_FILTER = 'TOGGLE_FILTER'
export const TOGGLE_VIEWMODE = 'TOGGLE_VIEWMODE'
export const TOGGLE_LOCATION_FILTER = 'TOGGLE_LOCATION_FILTER'
export const CLOSE_ALL_SIDEBARS = 'CLOSE_ALL_SIDEBARS'

export const TOGGLE_GRIDVIEW = 'TOGGLE_GRIDVIEW'

export const TOGGLE_COMPAREMODE = 'TOGGLE_COMPAREMODE'
export const SET_COMPARE_LEFT_PLOT = 'SET_COMPARE_LEFT_PLOT'
export const SET_COMPARE_RIGHT_PLOT = 'SET_COMPARE_RIGHT_PLOT'

export const SET_ORIGINAL_MEASUREMENT = 'SET_ORIGINAL_MEASUREMENT'

export const SET_MODEL_COMPARE = 'SET_MODEL_COMPARE'
export const SET_MODEL_COMPARE_INDEX = 'SET_MODEL_COMPARE_INDEX'
export const SET_SHOW_PLOT_COMPARE = 'SET_SHOW_PLOT_COMPARE'
//export const MODEL_ASIDE_VIEW = "MODEL_ASIDE_VIEW"

export const SET_ENLARGED_ASSETS = 'SET_ENLARGED_ASSETS'

export const TOGGLE_SCREENS = 'TOGGLE_SCREENS'

export const SET_SPECIFICATION_COMPARE_MODE = 'SET_SPECIFICATION_COMPARE_MODE'
export const SET_SPECIFICATION_COMPARE = 'SET_SPECIFICATION_COMPARE'

// Data
export const UPDATE_ALL_DATA = 'UPDATE_ALL_DATA'
export const UPDATE_PLOT_AVAILABILITY = 'UPDATE_PLOT_AVAILABILITY'
export const SET_SAVED = 'SET_SAVED'
export const CLEAR_SAVED = 'CLEAR_SAVED'

export const SET_ACTIVE_TYPE = 'SET_ACTIVE_TYPE'
export const TOGGLE_FAVOURITE = 'TOGGLE_FAVOURITE'

export const SET_ALL_FILTERS = 'SET_ALL_FILTERS'
export const SET_FILTER_OPTION = 'SET_FILTER_OPTION'
export const SET_FILTER_SET = 'SET_FILTER_SET'
export const CLEAR_FILTER_OPTION = 'CLEAR_FILTER_OPTION'
export const CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS'

export const SET_LOCATION_FILTER_OPTION = 'SET_LOCATION_FILTER_OPTION'
export const SET_ACTIVE_LOCATION_TAB = 'SET_ACTIVE_LOCATION_TAB'
export const SET_LOCATION_SEARCH = 'SET_LOCATION_SEARCH'
export const CLEAR_ALL_LOCATION_FILTERS = 'CLEAR_ALL_LOCATION_FILTERS'

export const SET_DEFAULT_TRANSPORT = 'SET_DEFAULT_TRANSPORT'

export const SET_VIEW_OPTION = 'SET_VIEW_OPTION'
export const SET_MEASUREMENT = 'SET_MEASUREMENT'

export const SET_MODEL_SAVED_FLOOR = 'SET_MODEL_SAVED_FLOOR'

export const SET_SPLASH_SCREENS = 'SET_SPLASH_SCREENS'
export const TOGGLE_SPLASH_SCREENS = 'TOGGLE_SPLASH_SCREENS'

// Service worker
export const INIT_SERVICE_WORKER = 'INIT_SERVICE_WORKER'
export const UPDATE_SERVICE_WORKER = 'UPDATE_SERVICE_WORKER'
