// Custom
import Scene from "components/scene"

function NotFound() {
  
  return (

    <Scene extraClass="fourofour">
      <h2>404. Sorry, that screen was not found</h2>
    </Scene>

  )

}

export default NotFound
