// Core
import { useEffect, useLayoutEffect, useRef, memo, useState } from 'react'
import { useLocation } from 'react-router'
// Data
import { useSelector } from 'react-redux'

// Animatation
import { AnimatePresence, motion, useAnimation, useMotionValue, useTransform } from "framer-motion"

// Custom
import Image from 'components/image'
import VideoEmbed from 'components/video-embed'
import TourEmbed from 'components/tour-embed'

// Icons
import LandingPageArrowPrompt from 'icons/LandingPageArrowPrompt'

// Video fallback
import videoFallbackImage from 'video-fallback.jpg'

import 'partials/splash.scss'

function usePrevious(value) {
  const previousValueRef = useRef(value)

  useEffect(() => {
    previousValueRef.current = value
  }, [value])

  return previousValueRef.current
}

function Splash({ index, data, updateSplashScreenValue, connected }){

  const location = useLocation()

  const { project } = useSelector(state => ({
    project: state.data.project
  }))

  // Animation and Drag Handler - -

  const prevIsOpen = usePrevious(data.isOpen)
  const controls = useAnimation()

  useLayoutEffect(() => {

    if (prevIsOpen && !data.isOpen) {
      controls.start('hidden')
    } else if (!prevIsOpen && data.isOpen) {
      // Bug in Framer causes this to be called before element is re-added to the DOM
      setTimeout(() => controls.start('visible'))
    } 

    // Hide splash when loading deeplink
    if(location.pathname !== "/"){
      controls.start('off')
      
      updateSplashScreenValue(index, 'isOpen', false)
    }

  }, [controls, data])

  
  useEffect(() => {

    if(data.isOpen){
      setTimeout(() => controls.start('visible'))
    }

  },[data.isOpen])

  const y = useMotionValue(0)
  const input = [-50, -300]
  const output = [1, 0]

  const opacity = useTransform(y, input, output)
  
  const onDragEnd = (event, info) => {
    const shouldClose = info.offset.y < -60
    controls.start(shouldClose ? 'hidden' : 'visible')
    updateSplashScreenValue(index, 'isOpen', !shouldClose)
  }

  const trackMouseWheel = (event) => {
    if(event.deltaY > 3){
      updateSplashScreenValue(index, 'isOpen', false)
    } 
  }

  let [height, setHeight] = useState(-window.innerHeight);
  
  useEffect(() => {
    
    const resizeListener = () => setHeight(height)
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  }, [])

  const splash = {
    visible: { y: 0, transition: { type: "spring", stiffness: 75, damping: 14 } },
    hidden: { y: height, transition: { type: "spring", stiffness: 50, damping: 12 } },
    off: { y: height, transition: { type: "tween", duration: 0 } }
  }

  const bounce = {
    open: { y: ["0px", "-30px"], transition: { duration: 0.4, repeat: Infinity, repeatType: 'reverse', ease: "easeOut" } },
    closed: { y: 0, transition: { duration: 0.4,  ease: "easeOut" } }
  }

  // - - -

  // Crossfade Gallery
  
  const [ imageIndex, setImageIndex ] = useState(0)

  useEffect(() => {
    if(project.splashPageBackground.length > 1){

      const timer = setInterval(() => {
        setImageIndex(parseInt(imageIndex) === (project.splashPageBackground.length - 1) ? 0 : imageIndex + 1)
      }, 5000);
  
      return () => clearInterval(timer)

    }
  })

  return (

    <AnimatePresence>

      { data.isOpen &&

        <motion.div
          style={{ y }}
          className="splash"
          drag="y"
          dragElastic={0.02}
          dragConstraints={{ bottom: 0 }}
          dragTransition={{ modifyTarget: target => target }}
          onDragEnd={onDragEnd}

          variants={splash}       
          animate={controls}
          exit={"hidden"}
          
          onWheel={(event) => trackMouseWheel(event) }
        >
          {{
            'gallery' : <>
              { data.hasOwnProperty('splashPageBackground') &&
              <>
                { data.splashPageBackground.length === 1
                    ? <Image className="splash-background" src={data.splashPageBackground[0]} />
                    : <AnimatePresence initial={false}>
                      <Image 
                        key={imageIndex}
                        className="splash-background" 
                        src={data.splashPageBackground[imageIndex]} 
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ opacity: { duration: 2 } }}
                      />
                      </AnimatePresence>
                  }
                  </>
                }
            
            </>,

            'video': 
              connected 
                ? <>{ data.splashPageVideo
                    ? <VideoEmbed embed={data.splashPageVideo} showButton={false} /> 
                    : <video src={data.splashPageVideoUrl} autoPlay loop muted playsInline />
                  }</>
                : <Image className="splash-background" src={videoFallbackImage} />,

            'tour': 
              <TourEmbed embed={data.splashPageTour} height={'100vh'} thumbnail={""} showButton={false} />
              
          }[data.type]}

          {/* TODO: Ditch h1, h3's - put opacity on images. */}
          { data.splashDevelopmentLogo && 
            <motion.h1 style={{ opacity, maxWidth: data.splashLogoMaxWidth }}>
              <Image src={data.splashDevelopmentLogo} alt="Development Logo" />
            </motion.h1>
          }
          
          { data.splashDeveloperLogo && 
            <motion.h3 style={{ opacity }} transition={{ delay: 0.2 }}>
              <Image src={data.splashDeveloperLogo} alt="Developer Logo" />
            </motion.h3> 
          }

          { data.splashPageText && 
            <motion.div style={{ opacity, maxWidth: data.splashPageTextMaxWidth }} transition={{ delay: 0.2 }} dangerouslySetInnerHTML={{ __html: data.splashPageText}}></motion.div> 
          }

          <motion.button 
            className="splash-trigger" 
            onClick={() => updateSplashScreenValue(index, 'isOpen', false)} 
            animate={data.isOpen ? "open" : "closed" }
            variants={bounce}
          > 
            <LandingPageArrowPrompt />
          </motion.button>
          

        </motion.div>

      }

    </AnimatePresence>
  
  )

}

export default memo(Splash)