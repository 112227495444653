import * as React from "react";

const SvgGalleryArrowRight = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 48"
    width="1em"
    height="1em"
    role="img"
    {...props}
  >
    <path d="M0 0v48l24-24z" />
  </svg>
);

export default SvgGalleryArrowRight;
