// Core 
import { motion } from "framer-motion"
import { useHistory } from "react-router-dom";

// Custom
import Image  from 'components/image'

// Styles 
import 'components/reactive-grid.scss' 

function ReactiveGrid({ children }){

  return (
    <div className="reactive-grid" data-tiles={ children.length }>
      { children }
    </div>
  )

}

export function ReactiveGridItem({ index, location, newWindow = false, src, video = false, title, subtitle, additionalFn = false }) {
  
  const history = useHistory();

  const filteredRoute = () => {

    if(additionalFn){ additionalFn() }

    if(location.includes('http://') || location.includes('https://')){
      setTimeout(() => window.open(location, newWindow ? '_blank' : '_self'))
    } else {
      setTimeout(() => history.push(location))
    }

  }

  return (
    <motion.button onClick={() => filteredRoute()} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.7, delay: 0.05 * index }}>
      { video 
        ? <video src={ video } autoPlay loop muted playsInline></video>
        : <Image src={ src } alt="" />
      } 
      <div>
        <h3>{ title }</h3>
        { subtitle && 
          <span className="rg-subtitle" dangerouslySetInnerHTML={{ __html: subtitle }} />
        }
      </div>
    </motion.button>
  )

}

export default ReactiveGrid