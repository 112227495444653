// Data
import { useSelector } from "react-redux"

// Custom
import Image from 'components/image'
import DropPanel from "components/drop-panel"

// Form
import Form from 'components/form'

function RegisterInterest() {

  const {registerOpen, project, formTitle} = useSelector(state => ({
    registerOpen: state.state.registerOpen,
    project: state.data.project,
    formTitle: state.data.form.registerYourInterest.settings.formTitle
  }))

  return (

    <DropPanel id="register" trigger={registerOpen}>
      
      <h1>{ formTitle || 'REGISTER YOUR INTEREST'}</h1>

      <Form formHandle="registerYourInterest" submitButtonText="Submit" />

      { project.footerLogo && 
        <Image className="footer-logo" src={project.footerLogo} alt={project.developmentName} />
      }

    </DropPanel>

  )

}

export default RegisterInterest