import CloseIcon from 'icons/Close'
// CSS
import 'components/button.scss'

const Button = ({ backgroundColor, color, size, active, label, extraClass, withToggle = false, ...props }) => {
  return (
    <button
      type="button"
      className={['button', `button--${size}`, `${active ? 'active' : ''}`, `${extraClass ? extraClass : ''}`].join(' ')}
      style={{ backgroundColor, color }}
      {...props}
    >
      {label}
      {withToggle && <CloseIcon />}
    </button>
  )
}

Button.defaultProps = {
  backgroundColor: null,
  color: null,
  size: 'regular',
  active: false,
  onClick: undefined,
}

export default Button
