import * as actionTypes from 'redux/actionTypes'
import data from 'project.json'

const initialState = data

export default function dataReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_ALL_DATA: {
      return action.payload.data
    }

    case actionTypes.UPDATE_PLOT_AVAILABILITY: {
      const { plots } = action.payload

      let _property = { ...state.modules.property }

      _property.plots.forEach((plot, index) => {
        const newPlot = plots.find((_plot) => _plot.id == plot.id)

        _property.plots[index].plotStatus = newPlot.plotStatus
        _property.plots[index].price = newPlot.price
        _property.plots[index].visibility = newPlot.visibility
      })

      return { ...state, modules: { ...state.modules, property: _property } }
    }

    case actionTypes.CLEAR_SAVED: {
      let _property = { ...state.modules.property }

      _property.plots.forEach((plot) => {
        plot.favourite = false
      })

      window.localStorage.setItem('saved', JSON.stringify([]))

      return { ...state, modules: { ...state.modules, property: _property } }
    }

    case actionTypes.SET_SAVED: {
      const { ids } = action.payload

      const _ids = typeof ids == 'string' ? JSON.parse(ids) : ids

      let _property = { ...state.modules.property }

      if (_ids && _ids.length > 0) {
        _ids.forEach((id) => {
          const index = _property.plots.findIndex((plot) => plot.id === id)
          _property.plots[index].favourite = true
        })
      }

      return { ...state, modules: { ...state.modules, property: _property } }
    }

    case actionTypes.TOGGLE_FAVOURITE: {
      const { id } = action.payload

      let _property = { ...state.modules.property }
      const index = _property.plots.findIndex((plot) => plot.id === id)

      _property.plots[index].favourite = !_property.plots[index].favourite

      const add = _property.plots[index].favourite

      // Short fn to add/remove to localStorage - -
      const ls = window.localStorage

      const savedJson = ls.getItem('saved')

      const saved = JSON.parse(savedJson)

      if (add) {
        saved.push(id)
      } else {
        const ind = saved.indexOf(id)

        if (ind > -1) {
          saved.splice(ind, 1)
        }
      }

      ls.setItem('saved', JSON.stringify(saved))
      // - - -

      return { ...state, modules: { ...state.modules, property: _property } }
    }

    case actionTypes.SET_MEASUREMENT: {
      const { measurement } = action.payload

      let _property = { ...state.modules.property }

      _property.defaultMeasurementColumn = measurement

      const sqMFilterIndex = _property.filters.findIndex((filter) => filter.handle === 'sqM')
      const sqFtFilterIndex = _property.filters.findIndex((filter) => filter.handle === 'sqFt')

      if (sqMFilterIndex > -1) {
        _property.filters[sqMFilterIndex].settings.currentMin = _property.filters[sqMFilterIndex].settings.min
        _property.filters[sqMFilterIndex].settings.currentMax = _property.filters[sqMFilterIndex].settings.max
      }

      if (sqFtFilterIndex > -1) {
        _property.filters[sqFtFilterIndex].settings.currentMin = _property.filters[sqFtFilterIndex].settings.min
        _property.filters[sqFtFilterIndex].settings.currentMax = _property.filters[sqFtFilterIndex].settings.max
      }

      return { ...state, modules: { ...state.modules, property: _property } }
    }

    // - - - - - - - -
    // SPLASH SCREENS
    // - - - - - - - -

    case actionTypes.SET_SPLASH_SCREENS: {
      const { splash } = action.payload
      return { ...state, splash: splash }
    }

    case actionTypes.TOGGLE_SPLASH_SCREENS: {
      const { show } = action.payload

      let _splash = [...state.splash]

      _splash.forEach((_, index) => {
        _splash[index].isOpen = show
      })

      return { ...state, splash: _splash }
    }

    // - - - - - - - - -
    // PROPERTY FILTERS
    // - - - - - - - - -

    // Set all filters
    case actionTypes.SET_ALL_FILTERS: {
      const { filters } = action.payload

      let _property = { ...state.modules.property }

      _property.filters = filters

      return { ...state, modules: { ...state.modules, property: _property } }
    }

    // Set Single Filter
    case actionTypes.SET_FILTER_OPTION: {
      const { handle, value } = action.payload

      let _property = { ...state.modules.property }

      const filterIndex = _property.filters.findIndex((filter) => filter.handle === handle)
      const filterType = _property.filters[filterIndex].type

      switch (filterType) {
        case 'single':
        case 'list':
          if (typeof value === 'number') {
            _property.filters[filterIndex].selected = value > -1 ? value : ''
          } else {
            _property.filters[filterIndex].selected = value !== '__any_value__' ? value : ''
          }

          break

        case 'multiple':
          const selectedIndex = _property.filters[filterIndex].selected.indexOf(value)

          if (selectedIndex > -1) {
            _property.filters[filterIndex].selected.splice(selectedIndex, 1)
          } else {
            _property.filters[filterIndex].selected.push(value)
          }

          break

        case 'toggle':
          _property.filters[filterIndex].selected = value

          break

        case 'range':
          console.log('RANGE:', value, _property.filters[filterIndex])
          _property.filters[filterIndex].settings.currentMin = value[0]
          _property.filters[filterIndex].settings.currentMax = value[1]

          break

        default:
          break
      }

      return { ...state, modules: { ...state.modules, property: _property } }
    }

    // Clear Single Filter
    case actionTypes.CLEAR_FILTER_OPTION: {
      const { handle } = action.payload

      let _property = { ...state.modules.property }

      const filterIndex = _property.filters.findIndex((filter) => filter.handle === handle)
      const filterType = _property.filters[filterIndex].type

      switch (filterType) {
        case 'toggle':
          _property.filters[filterIndex].selected = false
          break

        case 'single':
          _property.filters[filterIndex].selected = ''
          break

        case 'multiple':
          _property.filters[filterIndex].selected = []
          break

        case 'range':
          _property.filters[filterIndex].settings.currentMin = _property.filters[filterIndex].settings.min
          _property.filters[filterIndex].settings.currentMax = _property.filters[filterIndex].settings.max
          break

        default:
          break
      }

      return { ...state, modules: { ...state.modules, property: _property } }
    }

    // Set Multiple Filters (from Category view)
    case actionTypes.SET_FILTER_SET: {
      const { category } = action.payload

      let _property = { ...state.modules.property }

      category.filters.forEach(({ filter, value }) => {
        const filterIndex = _property.filters.findIndex((f) => f.handle === filter)
        const filterType = _property.filters[filterIndex].type

        switch (filterType) {
          case 'single':
          case 'list':
            if (typeof value === 'number') {
              _property.filters[filterIndex].selected = value > -1 ? value : ''
            } else {
              _property.filters[filterIndex].selected = value !== '__any_value__' ? value : ''
            }

            break

          case 'multiple':
            if (value === -1 || value === '__any_value__') {
              _property.filters[filterIndex].selected = []
            } else {
              _property.filters[filterIndex].selected.push(value)
            }

            break

          case 'toggle':
            _property.filters[filterIndex].selected = value

            break

          default:
            break
        }
      })

      return { ...state, modules: { ...state.modules, property: _property } }
    }

    // Clear ALL Filters
    case actionTypes.CLEAR_ALL_FILTERS: {
      let _property = { ...state.modules.property }

      const { excludeBeds, originalMeasurement } = action.payload

      _property.defaultMeasurementColumn = originalMeasurement

      _property.filters.forEach((filter) => {
        // Clear bedrooms only if on all properties page.
        if (filter.handle == 'bedrooms' && excludeBeds) {
          return false
        }

        switch (filter.type) {
          case 'toggle':
            filter.selected = false
            break

          case 'single':
            filter.selected = ''
            break

          case 'multiple':
            filter.selected = []
            break

          case 'range':
            filter.settings.currentMin = filter.settings.min
            filter.settings.currentMax = filter.settings.max
            break

          default:
            break
        }
      })

      return { ...state, modules: { ...state.modules, property: _property } }
    }

    // - - - - - - - - -
    // LOCATION FILTERS
    // - - - - - - - - -

    case actionTypes.SET_LOCATION_FILTER_OPTION: {
      const { slug } = action.payload

      let _placeTypes = [...state.modules.location.placeTypes]

      let selectedPlaceType = _placeTypes.find((placeType) => placeType.slug === slug)

      selectedPlaceType.active = !selectedPlaceType.active

      return { ...state, modules: { ...state.modules, location: { ...state.modules.location, placeTypes: _placeTypes } } }
    }

    case actionTypes.SET_ACTIVE_LOCATION_TAB: {
      const { slug } = action.payload

      let _placeTypes = [...state.modules.location.placeTypes]

      if (!slug) {
        _placeTypes.forEach((pType) => {
          pType.active = true
        })

        return { ...state, modules: { ...state.modules, location: { ...state.modules.location, placeTypes: _placeTypes } } }
      }

      _placeTypes.forEach((pType) => {
        pType.active = false
      })

      let selectedPlaceType = _placeTypes.find((placeType) => placeType.slug === slug)

      selectedPlaceType.active = true

      return { ...state, modules: { ...state.modules, location: { ...state.modules.location, placeTypes: _placeTypes } } }
    }

    case actionTypes.CLEAR_ALL_LOCATION_FILTERS: {
      let _placeTypes = [...state.modules.location.placeTypes]

      _placeTypes.forEach((placeType) => {
        placeType.active = true
      })

      return { ...state, modules: { ...state.modules, location: { ...state.modules.location, placeTypes: _placeTypes } } }
    }

    case actionTypes.SET_DEFAULT_TRANSPORT: {
      const { type } = action.payload

      return { ...state, modules: { ...state.modules, location: { ...state.modules.location, defaultTransport: type } } }
    }

    default:
      return state
  }
}
