// Animation
import { motion } from "framer-motion"
import { variants } from "utils/animation"

// SCSS
import "components/scene.scss"

function Scene({ children, extraClass, ...props}){

  return (
    <motion.div 
      className={['scene', `${extraClass ? extraClass : ''}`].join(' ')} 
      initial="initial" 
      animate="in" 
      exit="out" 
      variants={variants.page}
      {...props}
    >
      {children}
    </motion.div>
  )

}

export default Scene