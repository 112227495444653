import * as React from "react";

const SvgFormTick = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    width="1em"
    height="1em"
    role="img"
    {...props}
  >
    <path d="M8.4 30 0 18.9l1.6-1.3 6.8 9.1L28.4 0 30 1.2z" />
  </svg>
);

export default SvgFormTick;
