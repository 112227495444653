// Core
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Data
import { useDispatch } from 'react-redux';
import { setEnlargedAssets } from 'redux/actions'

// Custom
import Image from 'components/image'
import IconButton from 'components/icon-button';

// Icons
import ThreeSixtyButton from 'icons/360Button'
import ExpandButton from 'icons/ExpandButton'

// CSS
import 'components/tour-embed.scss'

function TourEmbed({ embed, thumbnail, height, showButton = false, extraClass = false, withZoom = false }) {

  const dispatch = useDispatch()
  const history = useHistory()

  const [trigger, setTrigger] = useState(false)

  const enlargeAssets = () => {

    dispatch( setEnlargedAssets({
      enlargedType: 'tour',
      enlargedAssets: [embed]
    }) )

    setTimeout(() => {
      history.push('/enlarge')

    })

  }

  return (

    <div className={['tour-embed', extraClass && extraClass].join(' ')}>

      { showButton

        ? trigger 
        
          ? <div dangerouslySetInnerHTML={{ __html: embed }} style={{ height: height + 'px' }}></div> 

          : <div className="video-embed-placeholder" style={{ height: height + 'px' }}>

              <Image src={thumbnail} />

              <IconButton onClick={() => setTrigger(true)}>
                <ThreeSixtyButton />
              </IconButton>
            </div>

        : <div dangerouslySetInnerHTML={{ __html: embed }} style={{ height: height + 'px' }}></div> 
          
      }

      { withZoom && 
        <IconButton extraClass="tour-zoom-button" onClick={() => enlargeAssets()}>
          <ExpandButton />
        </IconButton>
      }

    </div>

  )

}

export default TourEmbed