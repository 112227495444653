// Core
import { memo, useContext, useEffect } from 'react'
import classNames from 'classnames'
import { Switch, Route, useLocation } from 'react-router-dom'

// Hooks
import { useController } from 'utils/controller'

// Context
import { AppContext } from 'utils/context'

// Data
import { useDispatch, useSelector } from 'react-redux'
import { clearSaved, setFilterOption, setSaved, setSplashScreens } from 'redux/actions'

// Animtation
import { AnimatePresence, motion } from 'framer-motion'

// Partials
import Splash from 'partials/splash'
import Header from 'partials/header'
import Navigation from 'partials/navigation'
import RegisterInterest from 'partials/register-interest'
import Share from 'partials/share'
import ShareFavouritesSales from 'partials/share-favourites-sales'
import ConfirmClose from 'partials/confirm-close'
import ScreenSelector from 'partials/screen-selector'

// Components
import RegisterInterestButton from 'components/register-interest-button'

// Views
import Grid from 'modules/grid/grid'
import Contact from 'modules/contact/contact'
import Enlarge from 'views/enlarge'

// Utils
import ComponentLoader from 'utils/component-loader'
import Remote from 'utils/remote'
import RotationMessage from 'components/rotation-message'
import NotFound from 'views/404'
import { isMobile } from 'react-device-detect'
import ScreenSaver from 'components/screensaver'
import useShare from 'utils/share'
import CookieConsentBar from 'components/cookie-consent-bar'

//import AssetCachingNotice from 'components/asset-caching-notice'

function AppLayout({ visible, status }) {
  const location = useLocation()
  const controller = useController()
  const share = useShare()
  const dispatch = useDispatch()

  const appContext = useContext(AppContext)

  const storage = window.localStorage

  const { splashScreens, pages, theme, hideRegisterYourInterestButton, deviceInfo } = useSelector(({ data, device }) => ({
    splashScreens: data.splash,
    pages: data.pages,
    theme: data.theme,
    hideRegisterYourInterestButton: data.project ? data.project.hideRegisterYourInterestButton : false,
    deviceInfo: device.info,
  }))

  const updateSplashScreenValue = (index, key, value) => {
    let _splashScreens = [...splashScreens]
    _splashScreens[index][key] = value

    dispatch(setSplashScreens(_splashScreens))
  }

  useEffect(() => {
    if (!location.search.includes('remote=false')) {
      controller.send({
        action: 'goToPage',
        pathname: `${location.pathname}${location.search}`,
        showSplash: false,
        hideSplash: false,
      })
    }
  }, [location])

  const pagesObj = {}
  let propertyBase = false

  const getPageData = (page) => {
    pagesObj['/' + page.uri] = page.type
    if (page.type == 'module' && page.module == 'property') {
      propertyBase = page.uri
    }
  }

  // TODO: Find a cleaner solution for this
  pages &&
    pages.forEach((page) => {
      getPageData(page)
      if (page.children.length > 0) {
        page.children.forEach((child) => {
          getPageData(child)
          if (child.children && child.children.length > 0) {
            child.children.forEach((grandchild) => {
              getPageData(grandchild)
              if (grandchild.children && grandchild.children.length > 0) {
                grandchild.children.forEach((greatgrandchild) => getPageData(greatgrandchild))
              }
            })
          }
        })
      }
    })

  useEffect(() => {
    const loadSavedFromCode = async (savedCode) => {
      const res = await share.retrievePlotList(savedCode)
      if (!res.success) {
        const { name, message } = res.error
        console.error({ name, message })
      }

      await storage.setItem('saved', JSON.stringify(res.data.data))

      dispatch(clearSaved())

      setTimeout(() => {
        dispatch(setSaved(res.data.data))
        dispatch(setFilterOption('favourite', true))
      }, 500)
    }

    const params = new URLSearchParams(location.search)
    const code = params.get('code')

    if (code) {
      setTimeout(() => loadSavedFromCode(code), 1000) // TODO... review timeout requirment
    }
  }, [])

  const classes = {
    APP: true,

    // App/PWA
    IOS: deviceInfo && deviceInfo.platform === 'ios',
    ANDROID: deviceInfo && deviceInfo.platform === 'android',
    ELECTRON: deviceInfo && deviceInfo.platform === 'electron',
    WEB: deviceInfo && deviceInfo.platform === 'web',

    // Device
    MOBILEDEVICE: isMobile,

    // Operating System
    WINDOWS: deviceInfo && deviceInfo.operatingSystem == 'windows',
    MAC: deviceInfo && deviceInfo.operatingSystem == 'mac',

    // Context
    MARKETINGSUITE: appContext === 'marketing-suite',
    PUBLIC: appContext === 'public',

    // Display content in mobile portrtait mode
    'MOB-PORT-SHOW': location.pathname == '/' || (pagesObj[location.pathname] !== 'gallery' && !location.pathname.includes('gallery') && !location.pathname.includes('enlarge')),

    // Hidden Register your interest button
    'HIDDEN-RYI': hideRegisterYourInterestButton,
  }

  return (
    <>
      <AnimatePresence exitBeforeEnter>
        {visible && (
          <motion.div key="app-layout" className={classNames(classes)} animate={{ opacity: 1 }} initial={{ opacity: 0 }} exit={{ opacity: 0 }} data-rounded={theme.rounded}>
            {splashScreens.map((splashScreen, key) => {
              if (
                splashScreen.visibility == 'all' ||
                (splashScreen.visibility == 'public' && appContext == 'public') ||
                (splashScreen.visibility == 'sales' && appContext == 'marketing-suite')
              ) {
                return <Splash key={key.toString()} index={key} data={splashScreen} updateSplashScreenValue={updateSplashScreenValue} connected={status.connected} />
              }

              return null
            })}

            {/* <AssetCachingNotice /> */}

            <ScreenSaver />

            {deviceInfo && deviceInfo.platform == 'electron' && process.env.REACT_APP_OVERSEAS && <ConfirmClose />}

            {!location.search.includes('header=false') && process.env.REACT_APP_HEADER !== 'false' && <Header />}

            <Navigation />

            {!hideRegisterYourInterestButton && <RegisterInterest />}

            <Share />

            {appContext == 'marketing-suite' && <ShareFavouritesSales base={propertyBase} />}

            <ScreenSelector />

            <RotationMessage />

            <RegisterInterestButton />

            <Remote />

            <AnimatePresence exitBeforeEnter>
              <Switch location={location} key={location.pathname}>
                <Route key={`view-index`} path="/" exact={true}>
                  <Grid children={pages} />
                </Route>

                {pages.map((page) => (
                  <Route key={`view-${page.slug}`} path={`/${page.uri}`}>
                    <ComponentLoader page={page} />
                  </Route>
                ))}

                <Route key={`view-large`} path="/enlarge" exact={true}>
                  <Enlarge />
                </Route>

                <Route key={`view-contact`} path="/contact" exact={true}>
                  <Contact />
                </Route>

                <Route key="notfound">
                  <NotFound />
                </Route>
              </Switch>
            </AnimatePresence>
            <CookieConsentBar />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default memo(AppLayout)
