import * as React from "react";

const SvgBackButton = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 39 29"
    width="1em"
    height="1em"
    role="img"
    {...props}
  >
    <path d="M27.8 7H13.4V0L0 8l13.4 8V9h14.4c5.1 0 9.2 4 9.2 9s-4.1 9-9.2 9H5v2h22.8C34 29 39 24.1 39 18S34 7 27.8 7z" />
  </svg>
);

export default SvgBackButton;
