// Core
import { useState, useEffect } from 'react'

// Data
import { useDispatch, useSelector } from 'react-redux'
import { setSaved, setFilterOption, toggleShareFavourites } from 'redux/actions'

// Custom
import Image from 'components/image'
import DropPanel from 'components/drop-panel'
import Button from 'components/button'
import Form from 'components/form'

// Utils
import useShare from 'utils/share'

// QR
import QRCode from 'react-qr-code'

import './share-favourites.scss'

function RetrieveCodeForm({ retrieveCode, savedCode, setSavedCode }) {
  return (
    <form className="drop-panel-form" onSubmit={retrieveCode}>
      <h3>Load favourites</h3>
      <input type="text" placeholder="Enter a share code..." value={savedCode} onChange={(event) => setSavedCode(event.target.value)} />
      <Button type="submit" label={'Load Favourites'} />
    </form>
  )
}

function ShareFavouritesSales({ base }) {
  const share = useShare()
  const dispatch = useDispatch()

  const { shareFavouritesOpen, project, site, saved } = useSelector(({ state, data }) => ({
    shareFavouritesOpen: state.shareFavouritesOpen,
    project: data.project,
    site: data.site,
    saved: data.modules.property.plots.map((plot) => (plot.favourite ? plot.id : false)).filter((plot) => plot),
  }))

  const [shareCode, setShareCode] = useState('')
  const [savedCode, setSavedCode] = useState('')

  useEffect(() => {
    if (!shareFavouritesOpen) {
      setShareCode('')
    }
  }, [shareFavouritesOpen])

  const generateCode = async () => {
    const res = await share.generateCode(site.id, saved)

    if (!res.success) {
      alert('Unable to generate a new share code.... please try later')
    }

    setShareCode(res.data.code)
  }

  const retrieveCode = async (event) => {
    event.preventDefault()

    const res = await share.retrievePlotList(savedCode)

    if (!res.success) {
      alert('There was a problem retrieving the list of favourites, please try later')
    }

    window.localStorage.setItem('saved', JSON.stringify([]))
    dispatch(setSaved(res.data.data))
    dispatch(toggleShareFavourites(false))

    setTimeout(() => {
      dispatch(setFilterOption('favourite', true))
      setSavedCode('')
    }, 1000)
  }

  if (saved.length < 1) {
    return (
      <DropPanel id="share-favourites" trigger={shareFavouritesOpen}>
        <div style={{ maxWidth: 500, margin: '0 auto' }}>
          <h2>Please favourite 1 or more plots in order to share...</h2>
          <RetrieveCodeForm retrieveCode={retrieveCode} savedCode={savedCode} setSavedCode={setSavedCode} />
        </div>
      </DropPanel>
    )
  }

  return (
    <DropPanel id="share-favourites" trigger={shareFavouritesOpen}>
      <div className="share-fav-columns">
        <div>
          {saved.length > 0 ? (
            <>
              <h2>
                Share {saved.length} Favourite{saved.length > 1 && 's'}
              </h2>

              {shareCode ? (
                <>
                  <QRCode value={`https://${site.handle}.pixeldigital.ltd/${base}/schedule/all-properties?code=${shareCode}`} />
                  <p>Scan the QR code to share to your device</p>
                  <br />
                  <h3>Code: {shareCode}</h3>
                </>
              ) : (
                <>
                  <Button label={'Generate Code'} onClick={() => generateCode()} />
                  <br />
                </>
              )}

              <br />
            </>
          ) : (
            <h2>Please favourite 1 or more plots in order to share</h2>
          )}

          <hr />
          <RetrieveCodeForm retrieveCode={retrieveCode} savedCode={savedCode} setSavedCode={setSavedCode} />
        </div>

        <div>
          <h2>Share Brochure</h2>
          <Form
            disabled={!shareCode}
            formHandle="shareBrochure"
            submitButtonText="Send"
            sharePlot={saved}
            extraFieldData={[{ name: 'code', value: shareCode ? `https://${site.handle}.pixeldigital.ltd/${base}/schedule/all-properties?code=${shareCode}` : '' }]}
          />
          {!shareCode && <small style={{ display: 'block', marginTop: '-30px', color: '#ff6644' }}>Please generate a code in order to submit</small>}
        </div>
      </div>

      {project.footerLogo && <Image className="footer-logo" src={project.footerLogo} alt={project.developmentName} />}
    </DropPanel>
  )
}

export default ShareFavouritesSales
