import * as actionTypes from 'redux/actionTypes'

const initialState = {
  socket: null,
  lightingSocket: null,
  selectedDevice: null,
  connectedDevices: {},
  remoteData: {
    channel: 'default',

    sendLightingCommands: false,
    screensaverTimeout: '5000',
  },
}

export default function remoteReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_SOCKET: {
      const { socket } = action.payload
      return { ...state, socket: socket }
    }

    case actionTypes.SET_LIGHTING_SOCKET: {
      const { socket } = action.payload
      return { ...state, lightingSocket: socket }
    }

    case actionTypes.SET_SELECTED_DEVICE: {
      const { selectedDevice } = action.payload
      return { ...state, selectedDevice: selectedDevice }
    }

    case actionTypes.SET_CONNECTED_DEVICES: {
      const { connectedDevices } = action.payload
      return { ...state, connectedDevices: connectedDevices }
    }

    case actionTypes.SET_REMOTE_DATA: {
      const { remoteData } = action.payload
      return { ...state, remoteData: remoteData }
    }

    default:
      return state
  }
}
