import * as React from "react";

const SvgLinkedin = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 60 60"
    width="1em"
    height="1em"
    role="img"
    {...props}
  >
    <defs>
      <path id="linkedin_svg__a" d="M0 0h60v60H0z" />
    </defs>
    <clipPath id="linkedin_svg__b">
      <use xlinkHref="#linkedin_svg__a" overflow="visible" />
    </clipPath>
    <path
      d="M51.1 51.1h-8.9V37.2c0-3.3-.1-7.6-4.6-7.6-4.6 0-5.3 3.6-5.3 7.4v14.2h-8.9V22.5h8.5v3.9h.1c1.2-2.3 4.1-4.6 8.4-4.6 9 0 10.7 5.9 10.7 13.6v15.7zM13.3 18.6c-2.9 0-5.2-2.3-5.2-5.2 0-2.8 2.3-5.2 5.2-5.2 2.8 0 5.2 2.3 5.2 5.2 0 2.9-2.3 5.2-5.2 5.2m4.5 32.5H8.9V22.5h8.9v28.6zM55.6 0H4.4C2 0 0 1.9 0 4.3v51.3C0 58.1 2 60 4.4 60h51.1c2.4 0 4.4-1.9 4.4-4.3V4.3C60 1.9 58 0 55.6 0"
      clipPath="url(#linkedin_svg__b)"
    />
  </svg>
);

export default SvgLinkedin;
