// Core
import { useLocation, useHistory } from 'react-router-dom'

// Data
import { useDispatch, useSelector } from 'react-redux'
import { updateAllData, setLocationData } from 'redux/actions'

// Custom
import Image from 'components/image'
import HeaderLinks from 'partials/header-links'

// Utils
import { runUpdate } from 'utils/loader'

// CSS
import './header.scss'

const Header = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()

  const { site, project, menuOpen, shareOpen, shareFavouritesOpen, registerOpen } = useSelector(({ data, state }) => ({
    site: data.site,
    project: data.project,

    menuOpen: state.menuOpen,
    shareOpen: state.shareOpen,
    shareFavouritesOpen: state.shareFavouritesOpen,
    registerOpen: state.registerOpen,
  }))

  async function splashLoader() {
    if (menuOpen || shareOpen || shareFavouritesOpen || registerOpen) {
      return false
    }

    if (location.pathname === '/') {
      const updateRes = await runUpdate(site.id)
      const updateJson = await updateRes.json()

      if (updateJson.success) {
        updateJson.data.splash = updateJson.data.splash.map((splash) => {
          splash.isOpen = true
          return splash
        })

        dispatch(updateAllData(updateJson.data))

        const locationData = updateJson.data.modules.location.mapSettings
        dispatch(setLocationData(locationData))

        // CONFIG OVERRIDE - - -
        const configResponse = await fetch('/config.json')
        const configJson = await configResponse.json()

        if (configJson.hasOwnProperty('locationData')) {
          const mergedLocationData = configJson.locationData.concat(locationData)
          dispatch(setLocationData(mergedLocationData))
        }
      } else {
        console.error(updateJson)
      }
    } else {
      history.push('/')
    }
  }

  return (
    <header>
      <head-left>
        <HeaderLinks side="left" slug={location.pathname} />
      </head-left>

      <head-center>
        <button className="header-logo" onClick={() => splashLoader()}>
          {project?.headerLogo ? <Image src={project.headerLogo} alt={project.developmentName} /> : <h1>{project?.developmentName}</h1>}
        </button>
      </head-center>

      <head-right>
        <HeaderLinks side="right" slug={location.pathname} />
      </head-right>
    </header>
  )
}

export default Header
