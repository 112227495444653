export const variants = {

  spinner: {
    rotate: { loop: Infinity, ease: "linear", duration: 0.6 },
    scale: { duration: 0.5 }
  },
  
  page: {
    initial: { opacity: 0 },
    in: { opacity: 1 },
    out: { opacity: 0 },
  },
  
  // Drop Panels
  drop_panel_top: {
    open: { top: 0, transition: { type: "spring", stiffness: 90, damping: 14 } },
    closed: { top: '-100%', transition: { type: "spring", stiffness: 300, damping: 35 } }
  },

  drop_panel_bottom: {
    open: { bottom: 0, transition: { type: "spring", stiffness: 90, damping: 14 } },
    closed: { bottom: '-100%', transition: { type: "spring", stiffness: 300, damping: 35 } }
  },

  // Side Panels
  sidebar_left: {
    open: { left: '-20px', transition: { type: "spring", stiffness: 90, damping: 14, y:{ delay: 2 } } },
    closed: { left: '-400px', transition: { type: "spring", stiffness: 300, damping: 35 } }
  },
  
  sidebar_right: {
    open: { right: '-20px', transition: { type: "spring", stiffness: 90, damping: 14, y:{ delay: 2 } } },
    closed: { right: '-400px', transition: { type: "spring", stiffness: 300, damping: 35 } }
  },

  sidebar_bottom: { 
    open: { bottom: '0px', transition: { type: "spring", stiffness: 90, damping: 14 } },
    closed: { bottom: '-400px', transition: { type: "spring", stiffness: 300, damping: 35 } }
  },

  // Schedule Items
  item: {
    open: { height: '350px', transition: { type: "tween", duration: 0.5, ease: [1, 0, .35, 1] } },
    closed: { height: '0px', transition: { type: "tween", duration: 0.4, ease: [1, 0, 0, 1], delay: 0.5 } }
  },

  itemFixed: {
    open: { height: '350px', transition: { type: "tween", duration: 0 } },
    closed: { height: '0px', transition: { type: "tween", duration: 0, delay: 0 } }
  },

  itemImage: {
    open: { opacity: 1, transition: { type: "tween", duration: 0.5, delay: 0.6 } },
    closed: { opacity: 0, transition: { type: "tween", duration: 0.5 } }
  }
}

