import * as actionTypes from 'redux/actionTypes'

// REMOTE - -
export const setSocket = (socket) => ({
  type: actionTypes.SET_SOCKET,
  payload: {
    socket: socket,
  },
})

export const setLightingSocket = (socket) => ({
  type: actionTypes.SET_LIGHTING_SOCKET,
  payload: {
    socket: socket,
  },
})

export const setConnectedDevices = (connectedDevices) => ({
  type: actionTypes.SET_CONNECTED_DEVICES,
  payload: {
    connectedDevices: connectedDevices,
  },
})

export const setRemoteData = (remoteData) => ({
  type: actionTypes.SET_REMOTE_DATA,
  payload: {
    remoteData: remoteData,
  },
})

export const setLocationData = (locationData) => ({
  type: actionTypes.SET_LOCATION_DATA,
  payload: {
    locationData: locationData,
  },
})

export const disableScreenSaver = () => ({
  type: actionTypes.DISABLE_SCREEN_SAVER,
  payload: {},
})

export const setSelectedDevice = (selectedDevice) => ({
  type: actionTypes.SET_SELECTED_DEVICE,
  payload: {
    selectedDevice: selectedDevice,
  },
})

// DEVICE - -
export const setDeviceInfo = (info) => ({
  type: actionTypes.SET_DEVICE_INFO,
  payload: {
    info: info,
  },
})
export const setDevicePlatform = (platform) => ({
  type: actionTypes.SET_DEVICE_PLATFORM,
  payload: {
    platform: platform,
  },
})

// STATE - -
export const setLoading = (appLoading) => ({
  type: actionTypes.SET_LOADING,
  payload: {
    appLoading: appLoading,
  },
})

export const displayReconnection = (displayReconnection) => ({
  type: actionTypes.DISPLAY_RECONNECTION,
  payload: { displayReconnection },
})

export const setTotalAssetsToCache = (total) => ({
  type: actionTypes.SET_TOTAL_ASSETS_TO_CACHE,
  payload: {
    total: total,
  },
})
export const setTotalAssetsCached = (count) => ({
  type: actionTypes.SET_TOTAL_ASSETS_CACHED,
  payload: {
    count: count,
  },
})

export const setAssetsLastCached = (datetime) => ({
  type: actionTypes.SET_ASSETS_LAST_CACHED,
  payload: {
    datetime: datetime,
  },
})

export const setOriginalMeasurement = (originalMeasurement) => ({
  type: actionTypes.SET_ORIGINAL_MEASUREMENT,
  payload: {
    originalMeasurement: originalMeasurement,
  },
})

// Drop panels
export const toggleMenu = (menuOpen) => ({
  type: actionTypes.TOGGLE_MENU,
  payload: {
    menuOpen: menuOpen,
  },
})

export const toggleShare = (shareOpen) => ({
  type: actionTypes.TOGGLE_SHARE,
  payload: {
    shareOpen: shareOpen,
  },
})

export const toggleShareFavourites = (shareFavouritesOpen) => ({
  type: actionTypes.TOGGLE_SHARE_FAVOURITES,
  payload: {
    shareFavouritesOpen: shareFavouritesOpen,
  },
})

export const toggleRegister = (registerOpen) => ({
  type: actionTypes.TOGGLE_REGISTER,
  payload: {
    registerOpen: registerOpen,
  },
})

export const toggleConfirmDialog = (confirmDialogOpen) => ({
  type: actionTypes.TOGGLE_CONFIRM_DIALOG,
  payload: {
    confirmDialogOpen: confirmDialogOpen,
  },
})

export const closeAllPanels = () => ({
  type: actionTypes.CLOSE_ALL_PANELS,
  payload: {},
})

// Sidebars
export const toggleFilter = (filterOpen) => ({
  type: actionTypes.TOGGLE_FILTER,
  payload: {
    filterOpen: filterOpen,
  },
})

export const toggleViewMode = (viewOpen) => ({
  type: actionTypes.TOGGLE_VIEWMODE,
  payload: {
    viewOpen: viewOpen,
  },
})

export const toggleLocationFilter = (locationFilterOpen) => ({
  type: actionTypes.TOGGLE_LOCATION_FILTER,
  payload: {
    locationFilterOpen: locationFilterOpen,
  },
})

export const closeAllSidebars = () => ({
  type: actionTypes.CLOSE_ALL_SIDEBARS,
  payload: {},
})

// export const setModelAsideView = view => ({
//   type: actionTypes.MODEL_ASIDE_VIEW,
//   payload: {
//     view: view
//   }
// })

export const setShowPlotCompare = (active) => ({
  type: actionTypes.SET_SHOW_PLOT_COMPARE,
  payload: {
    active: active,
  },
})

export const toggleScreenSelector = (screensOpen) => ({
  type: actionTypes.TOGGLE_SCREENS,
  payload: {
    screensOpen: screensOpen,
  },
})

// Grid view
export const toggleGridView = (gridView) => ({
  type: actionTypes.TOGGLE_GRIDVIEW,
  payload: {
    gridView: gridView,
  },
})

// Compare mode
export const toggleCompareMode = (compareMode) => ({
  type: actionTypes.TOGGLE_COMPAREMODE,
  payload: {
    compareMode: compareMode,
  },
})

export const setCompareLeftPlot = (plot) => ({
  type: actionTypes.SET_COMPARE_LEFT_PLOT,
  payload: {
    plot: plot,
  },
})

export const setCompareRightPlot = (plot) => ({
  type: actionTypes.SET_COMPARE_RIGHT_PLOT,
  payload: {
    plot: plot,
  },
})

export const setModelCompareables = (plots) => ({
  type: actionTypes.SET_MODEL_COMPARE,
  payload: {
    plots: plots,
  },
})

export const setModelCompareIndex = (index) => ({
  type: actionTypes.SET_MODEL_COMPARE_INDEX,
  payload: {
    index: index,
  },
})

// Data
export const updateAllData = (data) => ({
  type: actionTypes.UPDATE_ALL_DATA,
  payload: {
    data: data,
  },
})

export const updatePlotAvailability = (plots) => ({
  type: actionTypes.UPDATE_PLOT_AVAILABILITY,
  payload: {
    plots: plots,
  },
})

export const setSaved = (ids) => ({
  type: actionTypes.SET_SAVED,
  payload: {
    ids: ids,
  },
})

export const clearSaved = () => ({
  type: actionTypes.CLEAR_SAVED,
  payload: {},
})

export const toggleFavourite = (id) => ({
  type: actionTypes.TOGGLE_FAVOURITE,
  payload: {
    id: id,
  },
})

export const setSplashScreens = (splash) => ({
  type: actionTypes.SET_SPLASH_SCREENS,
  payload: {
    splash: splash,
  },
})

export const toggleAllSplashScreens = (show) => ({
  type: actionTypes.TOGGLE_SPLASH_SCREENS,
  payload: {
    show: show,
  },
})

// Property Filters

export const setAllFilters = (filters) => ({
  type: actionTypes.SET_ALL_FILTERS,
  payload: {
    filters: filters,
  },
})

export const setFilterOption = (handle, value) => ({
  type: actionTypes.SET_FILTER_OPTION,
  payload: {
    handle: handle,
    value: value,
  },
})

export const clearFilterOption = (handle) => ({
  type: actionTypes.CLEAR_FILTER_OPTION,
  payload: {
    handle: handle,
  },
})

export const setFilterSet = (category) => ({
  type: actionTypes.SET_FILTER_SET,
  payload: {
    category: category,
  },
})

export const clearAllFilters = (originalMeasurement, excludeBeds = false) => ({
  type: actionTypes.CLEAR_ALL_FILTERS,
  payload: {
    handle: 'all',
    excludeBeds: excludeBeds,
    originalMeasurement: originalMeasurement,
  },
})

// Location Filters

export const setLocationFilterOption = (slug) => ({
  type: actionTypes.SET_LOCATION_FILTER_OPTION,
  payload: {
    slug: slug,
  },
})

export const setActiveLocationTab = (slug) => ({
  type: actionTypes.SET_ACTIVE_LOCATION_TAB,
  payload: {
    slug: slug,
  },
})

export const clearAllLocationFilters = () => ({
  type: actionTypes.CLEAR_ALL_LOCATION_FILTERS,
  payload: {
    handle: 'all',
  },
})

export const setLocationSearch = (locationSearch) => ({
  type: actionTypes.SET_LOCATION_SEARCH,
  payload: {
    locationSearch: locationSearch,
  },
})

export const setDefaultTransport = (type) => ({
  type: actionTypes.SET_DEFAULT_TRANSPORT,
  payload: {
    type: type,
  },
})

export const setViewOption = (name, value) => ({
  type: actionTypes.SET_VIEW_OPTION,
  payload: {
    name: name,
    value: value,
  },
})

export const setMeasurement = (measurement) => ({
  type: actionTypes.SET_MEASUREMENT,
  payload: {
    measurement: measurement,
  },
})

// Model

export const setModelSavedFloor = (modelSavedFloor) => ({
  type: actionTypes.SET_MODEL_SAVED_FLOOR,
  payload: {
    modelSavedFloor: modelSavedFloor,
  },
})

// Specification

export const setSpecificationCompareMode = (specificationCompareMode) => ({
  type: actionTypes.SET_SPECIFICATION_COMPARE_MODE,
  payload: {
    specificationCompareMode: specificationCompareMode,
  },
})

export const setSpecificationCompare = (specificationCompare) => ({
  type: actionTypes.SET_SPECIFICATION_COMPARE,
  payload: {
    specificationCompare: specificationCompare,
  },
})

// Enlarged assets

export const setEnlargedAssets = (data) => ({
  type: actionTypes.SET_ENLARGED_ASSETS,
  payload: {
    enlargedType: data.enlargedType,
    enlargedAssets: data.enlargedAssets,
  },
})

// Service worker

export const initServiceWorker = () => ({
  type: actionTypes.INIT_SERVICE_WORKER,
})

export const updateServiceWorker = (registration) => ({
  type: actionTypes.UPDATE_SERVICE_WORKER,
  payload: registration,
})
