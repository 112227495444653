// Icons
import RotatePrompt from 'icons/RotatePrompt'

// SCSS
import 'components/rotation-message.scss'

function RotationMessage() {

  return (

    <div className="rotation-message">
      <RotatePrompt />
      <p>FOR THE BEST EXPERIENCE, PLEASE CHANGE YOUR DEVICE ORIENTATION TO PORTRAIT MODE</p>
    </div>

  )

}

export default RotationMessage