// Core
import { useContext } from 'react'
import { useHistory } from 'react-router-dom'

// Data
import { useDispatch, useSelector } from 'react-redux'
import { toggleMenu } from 'redux/actions'

// Context
import { AppContext } from 'utils/context'

// Custom
import DropPanel from 'components/drop-panel'

// Logo
import Pixel from 'pixel-logo-new.svg'

function Navigation() {
  const appContext = useContext(AppContext)
  const history = useHistory()
  const dispatch = useDispatch()

  const { menuOpen, pages, project, showPixelLogo, showInMenu } = useSelector((state) => ({
    menuOpen: state.state.menuOpen,
    pages: state.data.pages,
    project: state.data.project,
    showPixelLogo: state.data.theme.showPixelLogo,
    showInMenu: state.data.modules.contact.showInMenu,
  }))

  const delay = 250

  const filteredPages = pages.filter((page) => {
    if (page.visibility == 'public' && appContext !== 'public') {
      return false
    }

    if (page.visibility == 'sales' && appContext !== 'marketing-suite') {
      return false
    }

    if (page.visibility == 'hidden') {
      return false
    }

    return true
  })

  return (
    <DropPanel id="navigation" trigger={menuOpen}>
      <button
        onClick={() => {
          dispatch(toggleMenu(false))
          setTimeout(() => history.push('/'), delay)
        }}
      >
        Home
      </button>

      {filteredPages.map((page, key) => (
        <button
          key={key}
          onClick={() => {
            dispatch(toggleMenu(false))
            setTimeout(() => history.push(`${page.type == 'link' ? page.linkUrl : `/${page.uri}`}`), delay)
          }}
        >
          {page.title}
        </button>
      ))}

      {showInMenu && (
        <button
          onClick={() => {
            dispatch(toggleMenu(false))
            setTimeout(() => history.push(`/contact`), delay)
          }}
        >
          Contact
        </button>
      )}

      <div className="small-nav-links">
        {project.termsOfUseUrl && <button onClick={() => window.open(project.termsOfUseUrl, '_blank')}>Terms of use</button>}
        {project.termsOfUseUrl && project.privacyCookiePolicyUrl && <span></span>}
        {project.privacyCookiePolicyUrl && <button onClick={() => window.open(project.privacyCookiePolicyUrl, '_blank')}>Cookie policy</button>}
      </div>

      {showPixelLogo && <img className="pixel-logo" src={Pixel} alt="Pixel Digital" />}
    </DropPanel>
  )
}

export default Navigation
