// Core
import { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

// Data
import { useDispatch, useSelector } from 'react-redux'
import { setEnlargedAssets } from 'redux/actions'

// Hooks
import { useController } from 'utils/controller'

// Custom
import Image from 'components/image'
import IconButton from './icon-button'

// Vendor
import { Player, BigPlayButton } from 'video-react'
import 'video-react/dist/video-react.css'

// Icons
import RotatePrompt from 'icons/RotatePrompt'
import PlayButton from 'icons/PlayButton'
import ExpandButton from 'icons/ExpandButton'

// Video fallback
import videoFallbackImage from 'video-fallback.jpg'

import { Network } from '@capacitor/network'

// CSS
import 'components/video-gallery.scss'

function VideoGallery({ videoId, video, thumbnail, showButton = false, withZoom = false }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const controller = useController()

  const playerRef = useRef()

  const selectedDevice = useSelector(({ remote }) => remote.selectedDevice)

  console.log(selectedDevice)

  const [trigger, setTrigger] = useState(false)
  const [networkStatus, setNetworkStatus] = useState({})

  const [handlerBound, setHandlerBound] = useState(false)

  const [muted, setMuted] = useState(selectedDevice ? true : false)

  useEffect(() => {
    const getNetwork = async () => {
      const status = await Network.getStatus()
      setNetworkStatus(status)
    }

    getNetwork()
  }, [])

  useEffect(() => {
    if (trigger) {
      controller.send({ action: `videoGalleryTrigger${videoId}` })
    }
  }, [trigger])

  useEffect(() => {
    controller.on(`videoGalleryTrigger${videoId}Response`, ({ refKey }) => {
      setTrigger(true)
    })

    controller.on(`videoGalleryPause${videoId}Response`, ({ refKey, pauseValue }) => {
      if (pauseValue) {
        playerRef.current.pause()
        setMuted(true)
      } else {
        playerRef.current.play()
        setMuted(false)
      }
    })

    controller.on('setEnlargedAssetsResponse', ({ type, assets }) => {
      dispatch(
        setEnlargedAssets({
          enlargedType: type,
          enlargedAssets: assets,
        })
      )
    })

    if (playerRef.current && !handlerBound) {
      playerRef.current.subscribeToStateChange(handlePlayerStateChange)
      setHandlerBound(true)
    }
  })

  const enlargeAssets = () => {
    controller.send({ action: 'setEnlargedAssets', type: 'videoUrl', assets: [video] })

    dispatch(
      setEnlargedAssets({
        enlargedType: 'videoUrl',
        enlargedAssets: [video],
      })
    )

    setTimeout(() => history.push('/enlarge'))

    // TODO: If not able to remote control videos - re-add the below to stop remote navigation to this route.
    //setTimeout(() => history.push('/enlarge?remote=false'))
  }

  const handlePlayerStateChange = (state, prevState) => {
    if (state.paused != prevState.paused) {
      console.log('PAUSED', state.paused)

      controller.send({ action: `videoGalleryPause${videoId}`, refKey: videoId, pauseValue: state.paused })
    }

    //console.log(state, prevState)
  }

  return (
    <div className="video-gallery">
      {networkStatus.connected ? (
        <>
          {showButton ? (
            trigger ? (
              <Player ref={playerRef} fluid={false} width="100%" height="100%" src={video} muted={muted} autoPlay playsInline>
                <BigPlayButton position="center" />
              </Player>
            ) : (
              <div className="video-gallery-placeholder">
                <Image src={thumbnail} />

                <IconButton onClick={() => setTrigger(true)}>
                  <PlayButton />
                </IconButton>
              </div>
            )
          ) : (
            <Player ref={playerRef} fluid={false} width="100%" height="100%" src={video} muted={muted} playsInline>
              <BigPlayButton position="center" />
            </Player>
          )}

          <span className="rotate">
            <RotatePrompt />
          </span>

          {withZoom && (
            <IconButton extraClass="video-zoom-button" onClick={() => enlargeAssets()}>
              <ExpandButton />
            </IconButton>
          )}
        </>
      ) : (
        <Image className="video-fallback" src={videoFallbackImage} />
      )}
    </div>
  )
}

export default VideoGallery
