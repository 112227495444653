import * as React from "react";

const SvgVimeo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
    width="1em"
    height="1em"
    role="img"
    {...props}
  >
    <path d="M50.5 19.9c-.2 4.2-3.1 10-8.8 17.3-5.9 7.7-10.9 11.5-15 11.5-2.5 0-4.7-2.3-6.4-7-1.2-4.3-2.3-8.6-3.5-12.8-1.3-4.7-2.7-7-4.2-7-.3 0-1.5.7-3.4 2l-2-2.6c2.1-1.9 4.3-3.8 6.3-5.6 2.9-2.5 5-3.8 6.4-3.9 3.4-.3 5.5 2 6.2 6.9.8 5.3 1.4 8.6 1.8 9.9 1 4.4 2 6.6 3.2 6.6.9 0 2.3-1.4 4.1-4.3s2.8-5 2.9-6.5c.3-2.5-.7-3.7-2.9-3.7-1 0-2.1.2-3.2.7 2.1-7 6.2-10.4 12.2-10.2 4.4.2 6.5 3.1 6.3 8.7M54 0H6C2.7 0 0 2.7 0 6v48c0 3.3 2.7 6 6 6h48c3.3 0 6-2.7 6-6V6c0-3.3-2.7-6-6-6" />
  </svg>
);

export default SvgVimeo;
