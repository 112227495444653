import * as React from "react";

const SvgFacebook = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
    width="1em"
    height="1em"
    role="img"
    {...props}
  >
    <path d="M60 30.2C60 13.5 46.6 0 30 0S0 13.5 0 30.2c0 15 11 27.5 25.3 29.8V38.9h-7.6v-8.7h7.6v-6.6c0-7.6 4.5-11.7 11.3-11.7 3.3 0 6.7.6 6.7.6v7.4h-3.8c-3.7 0-4.9 2.3-4.9 4.7v5.7H43L41.7 39h-7v21C49 57.7 60 45.2 60 30.2" />
  </svg>
);

export default SvgFacebook;
