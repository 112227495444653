// Core
import { useContext } from 'react'
import { useLocation } from 'react-router-dom'

// Data
import { useDispatch, useSelector } from 'react-redux'
import { toggleRegister } from 'redux/actions'

// Context
import { AppContext } from 'utils/context'

// CSS
import 'components/register-interest-button.scss'

function RegisterInterestButton() {
  const location = useLocation()
  const dispatch = useDispatch()
  const appContext = useContext(AppContext)

  const { hideRegisterYourInterestButton, registerOpen, filterOpen, viewOpen, locationFilterOpen, form } = useSelector(({ state, data }) => ({
    hideRegisterYourInterestButton: data.project.hideRegisterYourInterestButton,
    registerOpen: state.registerOpen,
    filterOpen: state.filterOpen,
    viewOpen: state.viewOpen,
    locationFilterOpen: state.locationFilterOpen,
    form: data.form,
  }))

  const criteria = appContext === 'public' && location.pathname == '/' && !hideRegisterYourInterestButton

  if (!criteria) return false

  return (
    <div className={!filterOpen && !viewOpen && !locationFilterOpen ? 'reg-int' : 'reg-int reg-int-hide-mob'}>
      <button onClick={() => dispatch(toggleRegister(!registerOpen))}>{form.registerYourInterest.settings.formButtonText}</button>
    </div>
  )
}

export default RegisterInterestButton
