import * as React from "react";

const SvgPinterest = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
    width="1em"
    height="1em"
    role="img"
    {...props}
  >
    <path d="M30 0C13.4 0 0 13.4 0 30c0 12.7 7.9 23.6 19.1 27.9-.3-2.4-.5-6 .1-8.6.5-2.3 3.5-14.9 3.5-14.9s-.9-1.8-.9-4.5c0-4.2 2.4-7.3 5.4-7.3 2.6 0 3.8 1.9 3.8 4.2 0 2.6-1.6 6.4-2.5 10-.7 3 1.5 5.4 4.4 5.4 5.3 0 9.4-5.6 9.4-13.7 0-7.2-5.2-12.2-12.5-12.2-8.5 0-13.5 6.4-13.5 13 0 2.6 1 5.3 2.2 6.8.2.3.3.6.2.9-.2 1-.7 3-.8 3.4-.1.5-.4.7-1 .4-3.7-1.7-6.1-7.2-6.1-11.6 0-9.5 6.9-18.2 19.8-18.2 10.4 0 18.5 7.4 18.5 17.3 0 10.3-6.5 18.7-15.6 18.7-3 0-5.9-1.6-6.9-3.4 0 0-1.5 5.7-1.9 7.1-.7 2.6-2.5 5.9-3.7 7.9 2.8.9 5.8 1.3 8.9 1.3 16.6 0 30-13.4 30-30S46.6 0 30 0" />
  </svg>
);

export default SvgPinterest;
